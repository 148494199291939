const ActionName = {
  LOADING: 'loading',
  SIGN_UP: 'signUp',
  USER_DATA: 'userData',
  TOGGLE_SIDENAV: 'toggleSidenav',
  LOGIN: 'login',

  CATEGORIES_DATA: 'categories_data',
  VIEW_CATEGORY: 'view_category',
  ASTROLOGERS_LIST: 'astrologers_list',
  VIEW_PRIVACY_DATA: 'view_privacy_data',
  VIEW_TERMS_DATA: 'view_terms_data',
  ASTROLOGER_DETAIL_DATA: 'astrologer_detail_data',
  SUNSIGN_DATA: 'sunsign_data',
  DAILY_HOROSCOPE_DATA: 'daily_horoscope_data',
  NEWS_DATA: 'news_data',
  NEWS_DETAIL: 'news_detail',
  TESTIMONIALS_DATA: 'testimonials_data',
  LANGUAGES_DATA: 'languages_data',
  ASTRO_VISIBILITY: 'astro_visibility',
  USER_CONNECTION_ID: 'user_connection_id',
  ASTRO_CONNECTION_ID: 'astro_connection_id',
  CALL_EVENT: 'call_event',
  // ADD_AMOUNT: 'add_amount',
  WALLET_BALANCE: 'wallet_balance',
  WALLET_AMOUNT: 'wallet_amount',
  WALLET_HISTORY: 'wallet_history',
  CUSTOMER: 'customer',
  ORDER: 'order',
  USER_ELIGIBLITY: 'user_eligiblity',
  INACTIVE_ASTROLOGER: 'inactive_astrologer',
  BLOCKED_ASTROLOGER: 'blocked_astrologer',
  CREATE_TESTIMONIAL: 'create_testimonial',
  BANNER_LIST: 'banner_list',
  ADSPACE_LIST: 'adspace_list',
  REVIEW_LIST: 'review_list',
  ASTRO_CALL_INFO: 'astro_call_info',
  PACKAGE_LIST: 'package_list',
  PACKAGE_DETAIL: 'package_detail',
  USER_CALL_HISTORY: 'user_call_history',
  ASTRO_CALL_HISTORY: 'astro_call_history',
  ADMIN_NOTIFICATION_LIST: 'admin_notification_list',
  CUSTOMER_LIST: 'customer_list',
  ONLINE_ASTROLOGERS: 'online_astrologers',
  VIDEO_CALLS_LIST: 'video_calls_list',
  DOWNLOAD_VIDEO_CALL: 'download_video_call',
  MISSED_CALL_LIST: 'missed_call_list',
  LIVEASTROLOGERSLIST: 'liveAstrologers',
  POPUPIMAGES: 'popupImages',
  POPUPIMAGESLIST: 'popupImagesList'
};
export default ActionName;
