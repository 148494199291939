import React from 'react';
import { createStyles, makeStyles } from "@mui/styles";
import { Avatar, Button, IconButton, Theme } from '@mui/material';
import MicOffIcon from '@mui/icons-material/MicOff';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import CallEndIcon from '@mui/icons-material/CallEnd';
import LocalImages from '../../../Utils/images';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        incomingWrapper: {
            width: '100%',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundColor: '#cccccc',
            zIndex: 999
        },
        OutgoingWrapper: {
            // width: '300px',
            borderRadius: '10px',
            boxShadow: '0 0 10px #ffffff8a',
            position: 'absolute',
            bottom: '20%',
            left: '50%',
            transform: 'translate(-50%, 100%)',
            padding: '12px 14px',
            backgroundColor: '#f0f8ff',
            "& ul": {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: '0 -10px',
                "& li" : {
                    margin: '0 10px'
                }
            }
        },
        callingAvatar: {
            width: '320px',
            height: '220px',
            borderRadius: '50%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        callingText: {
            marginTop: '30px',
            color: '#ffffff'
        },
        callerImage: {
            width: '120px',
            height: '120px',
            borderRadius: '50%',
            objectFit: 'cover',
            objectPosition: 'center'
        }
    })
);


interface Props {
   isMic: boolean,
   isCamera: boolean,
   userInfo: any,
//    isAcceptCall: boolean,
//    isRejectCall: boolean,
   callResponse: Function,
//    name: string,
//    shortName: string,
//    image: string
}

const IncomingView: React.FC<Props> = ({
    isMic,
   isCamera,
//    isAcceptCall,
//    isRejectCall,
   callResponse,
//    name,
//    shortName,
//    image,
userInfo
}) => {
    const classes = useStyles();
  const [micOn, setMicOn] = React.useState(true);
  const [videoCam, setVideoCam] = React.useState(true)
  const muteVoice = (isMic: boolean) => {
   setMicOn(isMic)
  }

  const camera = (isCamra: boolean) => {
    setVideoCam(isCamra);
   }

   const noResponse = () => {
    callResponse('NORESPONSE')
   }

const incomingAccept = () => {
    callResponse('ACCEPT');
    localStorage.setItem('incoming', 'false')
}

const incomingReject = () => {
    callResponse('REJECT');
    localStorage.setItem('incoming', 'false')
}

   setTimeout(() => {
    const isIncomming = localStorage.getItem('incoming');
    if(isIncomming && isIncomming === 'true'){
        noResponse()
    }
   }, 30000);

//    console.log("Info from Socket", userInfo && userInfo.msg.split('_'));
   const callerDetails = userInfo.msg.split('_')
    return (
        <React.Fragment>
            <div className={classes.incomingWrapper}>
                <audio autoPlay loop>
                    <source src={LocalImages.CALLER_TONE} type="audio/mpeg"></source>
                </audio>
            <div className={classes.callingAvatar}>
                {/* <div className='call_animation'>{shortName}</div> */}
                <div className='call_animation'>
                    {
                        userInfo && callerDetails[5] !== null || callerDetails[5] !== '' ?
                        <Avatar
                            alt={callerDetails[3]}
                            src={callerDetails[6]}
                            sx={{ width: 120, height: 120 }}
                            />:
                            <Avatar
                            sx={{ width: 120, height: 120 }}
                            >{callerDetails[4]}</Avatar>
                    }
                </div>
                <p className={classes.callingText}>
                    {userInfo && callerDetails[3]} {userInfo && callerDetails[4]} Calling...
                </p>
                {/* <p className={classes.callingText}>Calling {name}...</p> */}
            </div>
            <div className={classes.OutgoingWrapper}>
                <ul>
                    {isMic ?
                    <li>
                        <IconButton aria-label="audio" onClick={() => {muteVoice(!micOn)}}>
                            {micOn ?  <KeyboardVoiceIcon /> : <MicOffIcon />}
                        </IconButton>
                    </li> : '' }
                    {isCamera ?
                    <li>
                        <IconButton aria-label="video" onClick={() => {camera(!videoCam)}}>
                            {videoCam ? <VideocamIcon /> : <VideocamOffIcon />}
                        </IconButton>
                    </li> : ''}
                    <li>
                        <Button variant="contained"
                        sx={{
                           
                            borderRadius: '30px',
                            backgroundColor: 'green',
                            color: '#ffffff',
                        }}
                        onClick={() => {incomingAccept()}}
                        >
                            <CallEndIcon />
                        </Button>
                    </li>
                    <li>
                        <Button variant="contained"
                        sx={{
                           
                            borderRadius: '30px',
                            backgroundColor: 'red',
                            color: '#ffffff',
                        }}
                        onClick={() => {incomingReject()}}
                        >
                            <CallEndIcon />
                        </Button>
                    </li> 
                   
                </ul>
            </div>
            </div>
          
        </React.Fragment>
    )
}

export default IncomingView;