import React from "react";
import { Button, Link, Theme, Menu, MenuItem, IconButton, Drawer } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {  createStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import { ReducersModal } from "../../modal";
import Utils from "../../Utils";
import { PrimaryColors, SecondaryColors, UserNavigation } from "../../Utils/constData";
import { Navigate, createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { getUsersData } from "../../screen/user/profile/action";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import LocalImages from "../../Utils/images";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from "@mui/styles";
import { RealtimeClient } from "@supabase/realtime-js";
import { createClient } from "@supabase/supabase-js";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    vdeo: {objectFit: 'cover'},
    headerContainer: {
      zIndex: 999,
      backgroundColor: PrimaryColors[1100],
      position: "fixed",
      left: "0px",
      top: "0px",
      width: "100%",
      padding: "15px",
      boxSizing: "border-box",
      display: "flex",
      alignItems: 'center',
      justifyContent: "space-between",
      borderBottom: "1px solid #EAEAEA",
      [theme.breakpoints.between(100, 767)]:{
        display: 'none'
       },
    },
    toggleSideNav: {
      marginTop: "10px",
      marginBottom: "10px",
      width: "30%",
    },
    logo: {
        "& a":{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 400,
          fontSize: '22px',
          lineHeight: '1.2',
          color: '#000000',
          "& img": {
            margin: '0 15px 0 0'
          }
        },
        [theme.breakpoints.between(100, 767)]:{
          margin: '0 0 0 20px'
        }
    },
    userInfo: {
      display: 'flex',
      [theme.breakpoints.between(100, 767)]:{
        margin: '60px 0 0 0',
        alignItems: 'center',
        justifyContent: "space-between",
      },
    },
    menu: {
      // padding: '21px 0',
      "& ul": {
        display: 'flex',
        alignItems: 'center',
        margin: '-10px',
        [theme.breakpoints.between(100, 767)]:{
          margin: '20px 0 0 0',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: "space-between",
        },
        "& li": {
          margin: '10px',
          "& a": {
            cursor: 'pointer',
            padding: '8px 10px 12px',
            lineHeight: 1,
            borderRadius: '4px'
          }
        }
      }
    },
    activeLink: {
      backgroundColor: PrimaryColors[500],
      color: '#ffffff'
    },
    mobileHeader : {
      padding: '10px',
      flex: 1,
     
      alignItems: 'center',
     display: 'none',
      [theme.breakpoints.between(100, 767)]:{
       display: 'block'
      },
    },

    mobileMenu: {
      // position: 'fixed',
      // top: 0,
      // left: 0,
      width: '210px',
      height: '100vh',
      background: '#ffffff',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      zIndex: 99,
      backgroundImage: `url(${LocalImages.MOBILE_MENU})`,
      backgroundRepeat: 'no-repeat',
      // transform: 'translate(-250px, 0)',
      transition: '0.3s ease-in-out all'
    }
  })
);


 

function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const access_token = localStorage.getItem("access_token");
  const currentPage = window.location.pathname;
  const { data } = useSelector((state: ReducersModal) => state.userDataReducer);

  const { online_astrologers } = useSelector((state: ReducersModal) => state.onlineAstrologersReducers);

  // console.warn("currentPage", currentPage.split('/')[1]);
  const userRoll = localStorage.getItem('role_id');
  

  React.useEffect(() => {
    const user_data: any = localStorage.getItem("user_name");
    let parsed_user_data;
    try {
      parsed_user_data = JSON.parse(user_data)
    } catch (error) {
      console.log("++++++++++++++++++++++++++", error)
    }
    
    // console.log("new user data", parsed_user_data);
  
    dispatch({
      type: Utils.ActionName.USER_DATA,
            payload: { data: parsed_user_data },
    });
  }, [])
 
  const { toggle } = useSelector(
    (state: ReducersModal) => state.toggleSideNavReducer
  );

  const changeRoute = (path: string) => {
    // navigate(`${path}`);
    navigate({
      pathname: `${path}`,
    search: createSearchParams({
      page: "1",
      size: '10'
  }).toString()
});
    // () => {
  };

  const changeRouteM = (path: string) => {
    navigate(`${path}`);
    // () => {
      dispatch({
        payload: { toggle: !toggle },
        type: Utils.ActionName.TOGGLE_SIDENAV,
      });
    // }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const redirectTo = (url: any) => {
    setAnchorEl(null);
    navigate("/user/" + url);
    // console.log(url)
  };
  const redirectToM = (url: any) => {
    setAnchorEl(null);
    navigate("/user/" + url);
    dispatch({
      payload: { toggle: !toggle },
      type: Utils.ActionName.TOGGLE_SIDENAV,
    });
    // console.log(url)
  };

  const redirectToLogin = () => {
    navigate("/login");
  };

  const redirectToHome = () => {
    navigate("/home");
  };
 
  const logOutHandel = () => {
      localStorage.clear();
      window.location.reload();
  }

  const logOutHandelM = () => {
    localStorage.clear();
    window.location.reload();
}


  // const url = window.location.href;
  // console.warn(url.split('/').pop());
// React.useEffect(() => {
//   if(url.split('/').pop() == 'connect'){
//     window.scrollTo(0, 0);
//       document.body.classList.add('callScreen');
//   }
//   else{
//     document.body.classList.remove('callScreen');
//   }
// }, []);

const new_location = useLocation();
// console.log("NEW LOCATION", new_location.pathname.split('/'));

React.useEffect(() => {
  const loc_array = new_location.pathname.split('/');
if(!loc_array.includes('astrologers') && userRoll === '3'){
  navigate('/astrologer/dashboard')
  // console.log("condition 3")
}
else if(loc_array.includes('astrologer') && userRoll !== '3'){
  navigate('/home')
  // console.log("condition 2")
}
else if(!loc_array.includes('admin') && userRoll === '1'){
  navigate('/admin/dashboard  ')
  // console.log("condition 1")
}
}, [new_location])





// window.alert = function() {};
// window.addEventListener('beforeunload', function (e) {
//   e.preventDefault();
//   e.returnValue = '';
// });




  return (
    <div>
 
       <div className={classes.mobileHeader}>
              <div style={{display: 'flex', alignItems: 'center'}}>
              <IconButton aria-label="menu"
                onClick={() => {
                  dispatch({
                    payload: { toggle: !toggle },
                    type: Utils.ActionName.TOGGLE_SIDENAV,
                  });
                }}
              >
                <MenuIcon />
              </IconButton>

              <div className={classes.logo}>
                <Link href="#" underline="none" onClick={() => { redirectToHome() }}>
                  <img src={LocalImages.LOGO} alt="AKJ" width={70} />
                  <p>Aapkajyotish</p>
                </Link>
              </div>
              </div>
             
            </div>
      <Drawer
            anchor={'left'}
            open={toggle}
            onClose={
              () => {
                dispatch({
                  payload: { toggle: !toggle },
                  type: Utils.ActionName.TOGGLE_SIDENAV,
                });
              }
            }
          >
             <div>
           

            <div className={classes.mobileMenu}
              // style={{
              //   transform: `translate(${toggle ? 0 : '-210px'}, 0)`
              // }}
            >

              <div className={classes.userInfo}>
                {
                  data && access_token !== null ?
                    <Stack direction="row" spacing={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {
                        data.profile_image && data.profile_image == null || data.profile_image == "" ?
                          <Avatar sx={{ bgcolor: "#CEF4FF", width: '100px', height: '100px' }}>{data.shortName}</Avatar>
                          :
                          <Avatar src={data.profile_image} sx={{ bgcolor: "#CEF4FF", width: '100px', height: '100px' }} />
                      }
                      <div >
                        <span style={{
                          display: 'block',
                          width: '140px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          margin: '26px 0 0 0'
                        }}> {data.first_name}</span>

                      </div>
                    </Stack>
                    :
                    <Button variant="text"
                      sx={{
                        textTransform: 'capitalize',
                        fontSize: '18px'
                      }}
                      onClick={() => redirectToLogin()}
                    ><AccountCircleIcon />
                      <span style={{ marginLeft: '10px' }}>Login/Signup</span>
                    </Button>
                }
              </div>
              <div className={classes.menu}>
                <ul>
                  {UserNavigation && UserNavigation.map((values: any, index: any) => {
                    return (
                      <li key={index}>
                        <a onClick={() => changeRouteM(values.path)} className={currentPage == values.path ? `${classes.activeLink}` : ''}>
                          <span>{values.name}</span>
                        </a>
                      </li>

                    )
                  })}

                </ul>
                <ul>
                  <li><a onClick={(e: any) => { redirectToM("profile") }}>Profile</a></li>
                  <li><a onClick={(e: any) => { redirectToM("account") }}>My account</a></li>
                 
                  <li><a onClick={(e: any) => { redirectToM("wallet") }}>My Wallet</a></li>
                  <li><a onClick={logOutHandelM}>Logout</a></li>
                </ul>
              </div>
              {/* userCallHistory */}
            </div>
          </div>
          </Drawer>
         

          
          <div className={classes.headerContainer}>
            <div className={classes.logo}>
              <Link href="#" underline="none" onClick={() => { redirectToHome() }}>
                <img src={LocalImages.LOGO} alt="AKJ" width={70} />
                <p>Aapkajyotish</p>
              </Link>
            </div>
            <div className={classes.menu}>
              <ul>
                {UserNavigation && UserNavigation.map((values: any, index: any) => {
                  return (
                    <li key={index}>
                      <a onClick={() => changeRoute(values.path)} className={currentPage.split('/')[1] == values.active ? `${classes.activeLink}` : ''}>
                        <span>{values.name}</span>
                      </a>
                    </li>

                  )
                })}
               
              </ul>
            </div>
            <div style={{ display: 'flex' }}>
              {
                data && access_token !== null ?
                  <Stack direction="row" spacing={2}>
                    {
                      data.profile_image && data.profile_image == null || data.profile_image == "" ?
                        <Avatar sx={{ bgcolor: "#CEF4FF" }}>{data.shortName}</Avatar>
                        :
                        <Avatar src={data.profile_image} />
                    }

                    <div >
                      <Button
                        id="basic-button"
                        variant="contained"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleMenuClick}
                        sx={{
                          backgroundColor: PrimaryColors[500],
                          color: SecondaryColors[1200],
                          lineHeight: 1.3,
                          fontSize: '18px',
                          textTransform: 'capitalize'
                        }}
                      >
                        <span style={{
                          width: '70px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}> {data.first_name}</span>
                        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuItem onClick={(e: any) => { redirectTo("profile") }}>Profile</MenuItem>
                        {/* <MenuItem onClick={(e: any) => { redirectTo("account") }}>My account</MenuItem> */}
                        <MenuItem onClick={(e: any) => { redirectTo("wallet") }}>My Wallet</MenuItem>
                        <MenuItem onClick={(e: any) => { redirectTo("call_history") }}>Call History</MenuItem>
                        <MenuItem onClick={logOutHandel}>Logout</MenuItem>
                      </Menu>
                    </div>
                  </Stack>
                  :
                  <Button variant="text"
                    sx={{
                      textTransform: 'capitalize',
                      fontSize: '18px'
                    }}
                    onClick={() => redirectToLogin()}
                  ><AccountCircleIcon />
                    <span style={{ marginLeft: '10px' }}>Login/Signup</span>
                  </Button>
              }
            </div>
          </div>
      {/* <OutgoingView
      isMic = {false}
      isCamera = {false}
      /> */}
      {/* <IncomingView
        isMic = {false}
        isCamera = {false}
      /> */}
      {/* <UserCalling roomId={'aj@34j'}/> */}
    </div>
  );
}

export default Header;
