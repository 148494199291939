import { NewsDetailModel, NewsModel } from '../../../modal'
import Utils from '../../../Utils'
const { NEWS_DATA, NEWS_DETAIL } = Utils.ActionName
export const NewsDataReducer = (
    state: NewsModel = new NewsModel(),
    action: any
) => {
    switch (action.type) {
        case NEWS_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const NewsDetailReducer = (
    state: NewsDetailModel = new NewsDetailModel(),
    action: any
) => {
    switch (action.type) {
        case NEWS_DETAIL:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};