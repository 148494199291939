import React from 'react';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { saveCallOnfo, updateAstrologerVisibility } from './action';
import AstroFeedback from '../feedback/feedback';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { modifyDate } from '../../../Utils/constFunctions';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timer: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: 1.5,
      color: '#ffffff',
      position: 'fixed',
      zIndex: 1,
      padding: '20px',
      right: 0
    }

  })
);

export function getUrlParams(
  url: string = window.location.href
): URLSearchParams {
  let urlStr = url.split('?')[1];
  return new URLSearchParams(urlStr);
}

interface Props {
  roomId: any,
  callerId: any,
  roomExit: Function
}

const AstroCalling: React.FC<Props> = ({
  roomId,
  callerId,
  roomExit
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [zego_token, setZego_token] = React.useState('');

  const appID = process.env.REACT_APP_ZEGO_APP_ID;
  const serverSecret = process.env.REACT_APP_ZEGO_SERVER_URL;

  // generate Kit Token
  const user_data: any = localStorage.getItem("user_name");
  const parsed_user_data = JSON.parse(user_data);
  let userData = null;
  userData = parsed_user_data;
  const user_id = parsed_user_data.id;
  const user_name = `${parsed_user_data.first_name}`;

  const astrologerID = localStorage.getItem('astroData');
  const userInfo = {
    userID: String(user_id),
    userName: user_name
  };
  const [startTime, setStartTime] = React.useState<string | null>(null);
  const [endTime, setEndTime] = React.useState<string | null>(null);
  const [duration, setDuration] = React.useState<any | null>(null);
  const [counter, setCounter] = React.useState(0);
  const [callend, setCallend] = React.useState(true)

  interface CallInfo {
    call_uid: number,
    caller_user_id: number,
    call_type: string,
    start_time: string | null,
    end_time: string | null,
    duration: any | null
  }
  const [callInfo, setCallInfo] = React.useState<CallInfo>({
    "call_uid": roomId,
    "caller_user_id": callerId,
    "call_type": "VIDEO",
    "start_time": startTime,
    "end_time": endTime,
    "duration": "1"
  });


  const updateStartTime = async (time: string) => {
    setStartTime(time)
  }

  const updateEndime = async (time: string) => {
    setEndTime(time);
  }



  let ms = 0;
  setInterval(() => {
    // console.warn('Logs every second', ms);
    ms = ms + 1;

    if (ms === 61) {
      ms = 0;
      if (startTime !== null) {
        let currentTime = modifyDate(new Date().toLocaleString("en-US", {timeZone: 'Asia/Kolkata', hour12: false}));
        // console.log("currentTime", currentTime)
        const diff: any = ((+new Date(currentTime) - +new Date(startTime)) / 1000) / 60;
        console.warn("diff", Math.ceil(diff));
        setDuration(Math.ceil(diff));
        setCallInfo({ ...callInfo, duration: Math.ceil(diff) });
       ms == 0;
      }

    }
  }, 1000);



  React.useEffect(() => {
    setCallInfo({ ...callInfo, start_time: startTime });
  }, [startTime]);
  React.useEffect(() => {
    setCallInfo({ ...callInfo, end_time: endTime });
  }, [endTime])

  React.useEffect(() => {
    // console.log("callInfo", callInfo);
    if (callInfo.start_time !== null && callInfo.duration !== null) {
      
      dispatch(saveCallOnfo(callInfo))
    }

  }, [callInfo.start_time, callInfo.end_time, callInfo.duration])

  const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(Number(appID), String(serverSecret), roomId, String(user_id), user_name);
  // console.warn(Number(appID), String(serverSecret), roomID,  user_id,  user_name);
  if (zego_token != '') {
    // console.warn("ZIM INFO", userInfo, zego_token)
    // zimLogin(userInfo, zego_token)
  }

  const [totalTime, setTotalTime] = React.useState('');
  const timeCounter = () => {
      let secCount = 0;
      let minCount = 0;
      setInterval(function() {
          secCount = secCount + 1;
          if(secCount === 59){
              minCount = minCount + 1;
              secCount = 0
          }
          setTotalTime(minCount +":"+ secCount)
      }, 1000);
      // if(totalTime == maxTime){
      //   window.location.reload();
      //   localStorage.setItem("callStatus", "completed")
      // }
  }
  
  React.useEffect(() => {
  //     startTimer(1*60)
  timeCounter()
  }, [])

  let myMeeting = async (element: HTMLDivElement) => {

    // Create instance object from Kit Token.
    const zp = ZegoUIKitPrebuilt.create(kitToken);
    // start the call

    zp.joinRoom({
      container: element,
      showPreJoinView: false,
      showScreenSharingButton: false,
      branding: {
        logoURL: 'https://kds-statics-app-data.s3.amazonaws.com/test/logo.svg' // The branding LOGO URL.
      },
      showPinButton: false,
      onLeaveRoom: () => {
        // alert("leaved");
        roomExit('CALL_END');
        updateEndime(modifyDate(new Date().toLocaleString("en-US", {timeZone: 'Asia/Kolkata', hour12: false})));
        // window.location.reload();
        localStorage.setItem("callStatus", "completed");
        dispatch(updateAstrologerVisibility('online'))
      },
      onUserJoin: () => {
        updateStartTime(modifyDate(new Date().toLocaleString("en-US", {timeZone: 'Asia/Kolkata', hour12: false})));
        dispatch(updateAstrologerVisibility('dnd'));
        localStorage.setItem("callUID", roomId);
      },
      onUserLeave: () => {
        roomExit('CALL_END');
        updateEndime(modifyDate(new Date().toLocaleString("en-US", {timeZone: 'Asia/Kolkata', hour12: false})));
        // window.location.reload();
      localStorage.setItem("callStatus", "completed");
      dispatch(updateAstrologerVisibility('online'))
      },
      showLeavingView: false,
  
      videoResolutionDefault: ZegoUIKitPrebuilt.VideoResolution_720P,
      sharedLinks: [
        // {
        //   name: 'Personal link',
        //   url:
        //     window.location.origin +
        //     window.location.pathname +
        //     '?roomID=' +
        //     roomId,
        // },
      ],
      scenario: {
        mode: ZegoUIKitPrebuilt.OneONoneCall, // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
      },
      turnOnMicrophoneWhenJoining: true, 
      turnOnCameraWhenJoining: true,
      showTurnOffRemoteCameraButton: false,
      showTurnOffRemoteMicrophoneButton: false,
      showRemoveUserButton: false,
    });
    // setTimeout(() => {
    //   zp.destroy();
    //   setCallend(false)
    //  }, 60000);
  };

  const role_id = localStorage.getItem('role_id');
  
  return (
    <React.Fragment>
      <div>
      <div className={classes.timer}>{totalTime}</div>
        <div
          className="myCallContainer"
          ref={myMeeting}
          style={{ width: '100%', height: '100vh' }}
        ></div>

      </div>

      
    </React.Fragment>
  )
}

export default AstroCalling;