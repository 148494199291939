import Utils from "../../Utils";

export const updateAstrologerVisibility = (
    values: string,
    
) => {
    return (dispatch: any) => {
        const dataToSend = {
            values
        };
        Utils.api.putApiCallAkj(
            `${Utils.endPoints.setAstrologerVisibility}?visibilty=${values}`,
            {},
            (responseData: any) => {
                const { data } = responseData;
                // console.log("updated data VISLBILITY==================", responseData)
                if (responseData.status === 200) {
                    Utils.showAlert(1, "Visibility Updated");
                   
                } else {
                    Utils.showAlert(2, "Something went wrong");
                }
            },
            (error: any) => {
                
                let { data } = error;
                Utils.showAlert(2, "Something went wrong");
            }
        );
    };
}

export const getVisibility = () => {
    return (dispatch: any) => {
        Utils.api.getAkj(Utils.endPoints.astro_visibility,
            (responseData: any) => {
                const { data } = responseData;
                // console.log("ASTRO VISIBILITY", responseData)
                if (responseData.status === 200) {
                    dispatch({
                        payload: {
                            data : data
                        },
                        type: Utils.ActionName.ASTRO_VISIBILITY
                    })
                   
                } else {
                    Utils.showAlert(2, responseData.message)
                }
            }, (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message)
            })
    }
}