import { callsListModel, downloadCallsListModel, missedCallsListModel } from "../../../modal";
import Utils from "../../../Utils";
const { VIDEO_CALLS_LIST, DOWNLOAD_VIDEO_CALL, MISSED_CALL_LIST } = Utils.ActionName

export const callsListReducer = (
    state: callsListModel = new callsListModel(),
    action: any
) => {
    switch (action.type) {
        case VIDEO_CALLS_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const downloadCallsListReducer = (
    state: downloadCallsListModel = new downloadCallsListModel(),
    action: any
) => {
    switch (action.type) {
        case MISSED_CALL_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const missedCallsListReducer = (
    state: missedCallsListModel = new missedCallsListModel(),
    action: any
) => {
    switch (action.type) {
        case MISSED_CALL_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

