import { PopupListModel, TestimonialsModel } from '../../../modal'
import Utils from '../../../Utils'
const { TESTIMONIALS_DATA, POPUPIMAGES } = Utils.ActionName
export const TestimonialsReducer = (
    state: TestimonialsModel = new TestimonialsModel(),
    action: any
) => {
    switch (action.type) {
        case TESTIMONIALS_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const popupListReducer = (
    state: PopupListModel = new PopupListModel(),
    action: any
) => {
    switch (action.type) {
        case POPUPIMAGES:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};