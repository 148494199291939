import {UserPrivacyModal} from '../../../../modal';
import Utils from '../../../../Utils';

const {VIEW_PRIVACY_DATA} = Utils.ActionName;

export const userPrivacyDataReducer = (
    state: UserPrivacyModal = new UserPrivacyModal(),
    action: any
) => {
    switch (action.type) {
        case VIEW_PRIVACY_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}