import { Theme } from "@mui/material";
import {  createStyles, makeStyles } from "@mui/styles";
import React, { Suspense } from "react";
import Header from "../../components/header";
import { useSelector } from "react-redux";
import { ReducersModal } from "../../modal";
import Utils from "../../Utils";
import Footer from "../../components/Footer";
import Spinner from "../../components/Spinner";

const styles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      paddingTop: "100px",
      minHeight: "100vh",
      width: "100%",
      maxHeight: "100vh",
      // display: "flex",
      boxSizing: "border-box",
      [theme.breakpoints.between(0, 600)]: {
        paddingLeft: '0 !important',
        paddingTop: 0
      }
      // justifyContent: "space-between",
    },
    headerAndBodyContainer: {
      width: "100%",
      backgroundColor: "#F9FBFB",
      transition: "width 0.5s ease-in-out",
    },
    toggleOn: {
      transition: "width 0.5s ease-in-out",
      width: "98%",
    },
    bodyContainer: {
      width: "100%",
    },
  })
);

interface Props {
  children?: any;
}
function MainContainer({ children }: Props) {
  // const classes = styles();
  const { toggle } = useSelector(
    (state: ReducersModal) => state.toggleSideNavReducer
  );

  const url = window.location.href;
  const current_url = url.indexOf("connect") > -1;
  const admin_url = url.split('/').splice(3, 3);

  // console.warn("CURRENT_URL : ", admin_url);
  const classes = styles();
  return (
    <div className={classes.mainContainer}>
    <div
      className={
        toggle
          ? `${classes.headerAndBodyContainer} ${classes.toggleOn}`
          : classes.headerAndBodyContainer
      }
    >
      <Header />
      <Suspense fallback={"Loading..."}>
        <div className={classes.bodyContainer}>{children}</div>
      </Suspense>
      <Footer/>
    </div>
  </div>
  );
}

export default MainContainer;
