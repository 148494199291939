import React from "react";
import { Navigate } from "react-router-dom";
import MainContainer from "../mainContainer/index";
import Utils from "../../Utils/pathname";
import AdminContainer from "../adminContainer";
interface Props {
  component: JSX.Element;
  role: any
}

const PrivateRoute: React.FC<Props> = ({ component, role }: Props) => {
  // console.log("role", role);
const role_id = localStorage.getItem("role_id");
const access_token = localStorage.getItem("access_token");
  if(role == 1){
    // console.log("ROLL ID", role, role_id);
    return(
      <React.Fragment>
        {access_token !== null
         ? (
          <AdminContainer children={component} />
        ) : (
          <Navigate to={'login'} />
        )}
      </React.Fragment>
    )
  }
 else if(role == 3){
  // console.log("ROLL ID", role);
    return(
      <React.Fragment>
        {access_token !== null
         ? (
          <AdminContainer children={component} />
        ) : (
          <Navigate to={Utils.Login} />
        )}
      </React.Fragment>
    )
  }
  else{
    // console.log("ROLL ID", role);
    return (
      <React.Fragment>
          {/* <MainContainer children={component} /> */}
        
        
        {access_token !== null  ? (
          <MainContainer children={component} />
        ) : (
          <Navigate to={Utils.Login} />
        )}
      </React.Fragment>
    );
  }
 
};
export default PrivateRoute;
