import { UserConnectionIdModel } from '../../modal'
import Utils from '../../Utils'
const { USER_CONNECTION_ID } = Utils.ActionName
export const UserConnectionIdReducer = (
    state: UserConnectionIdModel = new UserConnectionIdModel(),
    action: any
) => {
    switch (action.type) {
        case USER_CONNECTION_ID:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};




