import { AstrologersModal, AstroDetailModal, LanguagesModel, AstroConnectionIdModel, UserEligibilityModel, RatingReviewModel } from '../../modal'
import Utils from '../../Utils'
const { ASTROLOGERS_LIST, ASTROLOGER_DETAIL_DATA, LANGUAGES_DATA, ASTRO_CONNECTION_ID, USER_ELIGIBLITY, REVIEW_LIST } = Utils.ActionName
export const astrologersDataReducer = (
    state: AstrologersModal = new AstrologersModal(),
    action: any
) => {
    switch (action.type) {
        case ASTROLOGERS_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const astrologerDetailDataReducer = (
    state: AstroDetailModal = new AstroDetailModal(),
    action: any
 ) => {
    switch (action.type) {
        case ASTROLOGER_DETAIL_DATA:
            return { ... state, ...action.payload};
        default:
            return state;
    }
 }

 export const LanguagesReducer = (
    state: LanguagesModel = new LanguagesModel(),
    action: any
 ) => {
    switch (action.type) {
        case LANGUAGES_DATA:
            return { ...state, ...action.payload};
        default:
            return state;
    }
 }

 


export const AstroConnectionIdReducer = (
    state: AstroConnectionIdModel = new AstroConnectionIdModel(),
    action: any
) => {
    switch (action.type) {
        case ASTRO_CONNECTION_ID:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const UserEligibilityReducer = (
    state: UserEligibilityModel = new UserEligibilityModel(),
    action: any
) => {
    switch (action.type) {
        case USER_ELIGIBLITY:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const RatingReviewReducer = (
    state: RatingReviewModel = new RatingReviewModel(),
    action: any
) => {
    switch (action.type) {
        case REVIEW_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};







