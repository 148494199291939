import { CategoriesListModal, ViewCategoryModal } from '../../../modal'
import Utils from '../../../Utils'
const { CATEGORIES_DATA, VIEW_CATEGORY } = Utils.ActionName
export const categoriesDataReducer = (
    state: CategoriesListModal = new CategoriesListModal(),
    action: any
) => {
    switch (action.type) {
        case CATEGORIES_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const viewCategoriesReducer = (
    state: ViewCategoryModal = new ViewCategoryModal(),
    action: any
) => {
    switch(action.type){
        case VIEW_CATEGORY:
            return{ ...state, ...action.payload};
        default:
            return state
    }
}