import { AstrologerCallHistoryModel, UserDataModal } from '../../../modal'
import Utils from '../../../Utils'
const { USER_DATA, USER_CALL_HISTORY } = Utils.ActionName
export const userDataReducer = (
    state: UserDataModal = new UserDataModal(),
    action: any
) => {
    switch (action.type) {
        case USER_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const UserCallHistoryReducer = (
    state: AstrologerCallHistoryModel = new AstrologerCallHistoryModel(),
    action: any
) => {
    switch (action.type) {
        case USER_CALL_HISTORY:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
