import { ToggleSidenavModal, AstroVisibilityModel, AstroCallInfoModel, onlineAstrologersModel } from '../../modal'
import Utils from '../../Utils'
const { TOGGLE_SIDENAV, ASTRO_VISIBILITY, ASTRO_CALL_INFO, ONLINE_ASTROLOGERS } = Utils.ActionName


export const toggleSideNavReducer = (
    state: ToggleSidenavModal = new ToggleSidenavModal(),
    action: any
) => {
    switch (action.type) {
        case TOGGLE_SIDENAV:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const AstroVisibilityReducer = (
    state: AstroVisibilityModel = new AstroVisibilityModel(),
    action: any
) => {
    switch (action.type) {
        case ASTRO_VISIBILITY:
            return {...state, ...action.payload};
        default:
            return state;
    }
};

export const AstroCallInfoReducer = (
    state: AstroCallInfoModel = new AstroCallInfoModel(),
    action: any
) => {
    switch (action.type) {
        case ASTRO_CALL_INFO:
            return {...state, ...action.payload};
        default:
            return state
    }
}

export const onlineAstrologersReducers = (
    state: onlineAstrologersModel = new onlineAstrologersModel(),
    action: any
) => {
    switch (action.type) {
        case ONLINE_ASTROLOGERS:
            return {...state, ...action.payload};
        default:
            return state
    }
}