import React from 'react';

import { Link, Theme } from '@mui/material';
import LocalImages from '../../Utils/images';
import { BottomNavigation, PrimaryColors, SocialIcons } from '../../Utils/constData';
import { useNavigate } from 'react-router-dom';
import { createStyles, makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {
            backgroundColor: PrimaryColors[700],
            padding: '60px 0',
            display: 'flex',
            color: '#ffffff',
            flexDirection: 'column',
            [theme.breakpoints.between(100, 767)]:{
               padding: '30px 15px'
              },
        },
        footer_inner: {
            maxWidth: '1120px',
            width: '100%',
            margin: '0 auto',
            display: 'flex',
            [theme.breakpoints.between(100, 767)]:{
               flexDirection: 'column'
              },
        },
        footer_col: {
            width: 'calc(100% / 3)',
            flex: '1',
            [theme.breakpoints.between(100, 767)]:{
                width: '100%',
                borderBottom: '1px solid #ffffff75',
                paddingBottom: '10px',
                marginBottom: '20px',
              },
            "& p": {
                lineHeight: 1.5,
                color: '#ffffff',
                fontSize: '14px',
                fontWeight: 400,
                marginBottom: '10px'
            },
            "& h3": {
                lineHeight: 1,
                marginBottom: '15px'
            },
        },
        copy_right: {
            paddingTop: '60px',
            [theme.breakpoints.between(100, 767)]:{
                paddingTop: '20px',
              },
            "& p": {
                textAlign: 'center',
                color: '#ffffff',
                fontSize: '16px',
                fontWeight: 400,
            }
        },
        usefulLinks: {
            width: '100%',
            "& ul": {
                display: 'flex',
                flexWrap: 'wrap',
                margin: '-10px',
                flex: 1,
                "& li": {
                    width: '50%',
                    padding: '10px',

                    "& a": {
                        color: '#ffffff',
                        fontSize: '16px',
                        fontWeight: 400,
                        cursor: 'pointer',
                        "&:hover": {
                            color: '#ffffff'
                        }
                    }
                }
            }
        },
        subTitle: {
            fontSize: '16px',
            marginBottom: '4px',
            textAlign: 'left'
        },
        email: {
            color: '#ffffff',
            fontSize: '16px',
            fontWeight: 400,
            cursor: 'pointer',
            textDecoration: 'none',
            "&:hover": {
                color: '#ffffff'
            }
        },
        social_icons: {
            "& ul": {
                display: 'flex',
                alignItems: 'center',
                margin: '-4px',
                "& li": {
                    margin: '4px'
                }
            }
        },
        apps: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            [theme.breakpoints.between(100, 767)]:{
                flexDirection: 'row',
                alignItems: 'center',
              
              },
        }



    })
);

function Footer() {
    const classes = useStyles();
    const navigate = useNavigate();
    const currentPage = window.location.pathname;
    const changeRoute = (path: string) => {
        navigate(`${path}`);
        // console.log(path)
    };
    const version = `v_011.06`;
    const date = new Date();
    const year = date.getFullYear();
  
    return (
        <div className={classes.footer}>
            <div className={classes.footer_inner}>
                <div className={classes.footer_col}>
                    {/* <h3>AKJ</h3> */}
                    <h4 className={classes.subTitle}>We are available 24/7</h4>
                    <br/>
                        {/* <p>KANAN DIGITAL SOLUTIONS <br />
                        House No- A-14, Gali NO-5/1,<br />
                        Shakti Vihar, Meethapur, Badarpur,<br />
                        New Delhi: -110044<br />
                    </p> */}

                    {/* <h4 className={classes.subTitle}>Telephone</h4>
                    <p>+91 7827917494</p>

                    <h4 className={classes.subTitle}>Fax</h4>
                    <p>+91 7827917494</p> */}

                    <h4 className={classes.subTitle}>Support</h4>
                    <p><a className={classes.email} href="mailto:support@aapkajyotish.com">support@aapkajyotish.com</a><br></br>
                    <a className={classes.email} href="mailto:info@aapkajyotish.com">info@aapkajyotish.com</a></p>
                </div>
                <div className={classes.footer_col}>
                    <div className={classes.usefulLinks}>
                        <ul>
                            {
                                BottomNavigation.map((values: any, index: any) => {
                                    return (
                                        <li key={index}>
                                            <a
                                                color='#ffffff'
                                                onClick={() => changeRoute(values.path)}
                                            >{values.name}</a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className={`${classes.footer_col} ${classes.apps}`}>
                    <a href="https://play.google.com/store/apps/details?id=com.kanan.apkajyotish&pli=1" target={'_blank'}><img src={LocalImages.PLAY_STORE_LOGO} alt="Playstore" /></a>


                            <div className={classes.social_icons}>
                               <ul>
                               {
                                    SocialIcons.map((values: any, index: any) => {
                                        return(
                                            <li key={index}>
                                                <a href={values.url} target="_blank" title={values.name}>
                                                <img src={values.icon} alt={values.name} />
                                                </a>
                                            </li>
                                        )
                                    })
                                }
                               </ul>
                            </div>
                </div>
            </div>
            <div className={classes.copy_right}>
                <p>copyright @ {year} aapkajyotish All rights reserved {version}</p>
            </div>
        </div>
    )
}
export default Footer;