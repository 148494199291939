import { BlockedAstrologersModel, InactiveAstrologersModel } from "../../../modal";
import Utils from "../../../Utils";
const { BLOCKED_ASTROLOGER, INACTIVE_ASTROLOGER } = Utils.ActionName

export const InactiveAstrologersReducer = (
    state: InactiveAstrologersModel = new InactiveAstrologersModel(),
    action: any
) => {
    switch (action.type) {
        case INACTIVE_ASTROLOGER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const BlockedAstrologersReducer = (
    state: BlockedAstrologersModel = new BlockedAstrologersModel(),
    action: any
) => {
    switch (action.type) {
        case BLOCKED_ASTROLOGER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};