import { DailyHoroscopeModal, SignUpDataModal } from '../../modal'
import Utils from '../../Utils'
const { SUNSIGN_DATA, DAILY_HOROSCOPE_DATA } = Utils.ActionName
export const sunsignDataReducer = (
    state: SignUpDataModal = new SignUpDataModal(),
    action: any
) => {
    switch (action.type) {
        case SUNSIGN_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const DailyHoroscopeDataReducer = (
    state: DailyHoroscopeModal = new DailyHoroscopeModal(),
    action: any
) => {
    switch (action.type) {
        case DAILY_HOROSCOPE_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};


