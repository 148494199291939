import { CreateTestimonialsModel } from "../../../modal";
import Utils from "../../../Utils";
const { CREATE_TESTIMONIAL } = Utils.ActionName

export const CreateTestimonialsReducer = (
    state: CreateTestimonialsModel = new CreateTestimonialsModel(),
    action: any
) => {
    switch (action.type) {
        case CREATE_TESTIMONIAL:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};