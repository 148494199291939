import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";

// ReactDOM.render(
  
//   <>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </>,
//   document.getElementById("root")
// );

const rootElement = document.getElementById("root");

if (rootElement && rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <>
     <Provider store={store}>
       <App />
     </Provider>
   </>, rootElement);
} else {
  ReactDOM.render(
    <>
     <Provider store={store}>
       <App />
     </Provider>
   </>, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
