import Utils from '../../../Utils';

export const getUsersData = () => {
    return (dispatch: any) => {
        Utils.api.getApiCallAkj( Utils.endPoints.userProfileData, "",
            (responseData: any) => {
                const { data } = responseData;
                // console.log("user data", responseData)
                if (responseData.status === 200) {
                    // const { items } = data
                    // console.log(data);
                    // debugger
                    dispatch({
                        payload: {
                            data : data
                        },
                        type: Utils.ActionName.USER_DATA
                    })
                } else {
                    Utils.showAlert(2, responseData.message)
                }
            }, (error: any) => {
                let { data } = error;
                Utils.showAlert(2, data.message)
            })
    }
}

export const updateUserData = (
    values: object,
    setSubmitting: Function,
) => {
    return (dispatch: any) => {
        const dataToSend = {
          values
        };
        Utils.api.putApiCallAkj(
          `${Utils.endPoints.updateProfileDta}` ,
          dataToSend.values,
          (responseData: any) => {
            const { data } = responseData;
            // console.log("updated data==================", responseData)
            if (responseData.status === 200) {
              Utils.showAlert(1, "Profile updated sucessfully");
              dispatch({
                type: Utils.ActionName.USER_DATA,
                payload: { data: data },
              });
            } else {
              Utils.showAlert(2, "Something went wrong11");
            }
          },
          (error: any) => {
            setSubmitting(false);
            let { data } = error;
            Utils.showAlert(2, data.message);
          }
        );
      };
}

export const generateUrl = (queryParameters: any, baseURL: any) => {
  const { pageNo, limit } = queryParameters;
  // console.log(queryParameters)
  return `${baseURL}?pageNo=${pageNo}&size=${limit}`;
};
export const getUserCallHistory = (queryParameters: any) => {
  return (dispatch: any) => {
      Utils.api.getApiCallAkj( generateUrl(queryParameters, Utils.endPoints.userCallHistory), "",
          (responseData: any) => {
              const { data } = responseData;
              if (responseData.status === 200) {
                  const { items, page } = data
                  // console.log(items)
                  dispatch({
                      payload: {
                          user_call_history : data.items
                      },
                      type: Utils.ActionName.USER_CALL_HISTORY
                  })
              } else {
                  Utils.showAlert(2, data.message)
              }
          }, (error: any) => {
              let { data } = error;
              Utils.showAlert(2, data.message)
          })
  }
}