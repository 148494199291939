import React from "react";
import { Suspense } from "react";
import { Navigate } from "react-router-dom";
import Utils from "../../Utils/pathname";
import LoginContainer from "../loginContainer/index";
import MainContainer from "../mainContainer";
interface Props {
  component?: JSX.Element;
  routeType?: number;
}
const role_id = localStorage.getItem("role_id");
const access_token = localStorage.getItem("access_token");
const location = window.location.href.split("/")[3];
// console.log(location)
const PublicRoute: React.FC<Props> = ({ component, routeType = 1 }) => {
  return (
    <React.Fragment>
       <MainContainer>{component}</MainContainer>
      {/* {localStorage.getItem("access_token") === null &&
      sessionStorage.getItem("access_token") === null ? (
        routeType === 2 ? (
          <Suspense fallback={""}>{component}</Suspense>
        ) : (
          <LoginContainer>{component}</LoginContainer>
        )
      ) : (
        <Navigate to={Utils.Home} />
      )} */}

      {/* {access_token === null 
      ?
      ( <LoginContainer>{component}</LoginContainer>) : (<Navigate to={Utils.Home} />)
      } */}
    </React.Fragment>
  );
};
export default PublicRoute;
// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxMzIsImV4cGlyZXMiOjE2NjgxMzMxNjguODUyNzg0fQ.mqsOjpOriglumafBXVAmMTBSFzMkChzIg-N9xwJ1p38