import { combineReducers } from 'redux';
import { userDataReducer } from '../screen/user/profile/reducer'
import { signUpDataReducer } from '../screen/signup/reducer'
import {toggleSideNavReducer, AstroVisibilityReducer} from '../components/sideNavigation/reducer'
// import {editableComponentReducer} from '../screen/videoEditor/reducer'
import { categoriesDataReducer } from '../screen/admin/categories/reducer'
import { viewCategoriesReducer } from '../screen/admin/categories/reducer'
import { astrologersDataReducer, astrologerDetailDataReducer } from '../screen/astrologers/reducer'
import { userPrivacyDataReducer } from '../screen/PublicScreens/cms/userPrivacy/reducer'
import { userTermsDataReducer } from '../screen/PublicScreens/cms/userTerms/reducer'
import { sunsignDataReducer } from '../screen/horoscope/reducer'
import { DailyHoroscopeDataReducer } from '../screen/horoscope/reducer'
import { NewsDataReducer } from '../screen/PublicScreens/News/reducer'
import { NewsDetailReducer } from '../screen/PublicScreens/News/reducer'
import { TestimonialsReducer } from '../screen/PublicScreens/Home/reducer'
import { LanguagesReducer } from '../screen/astrologers/reducer'
import { SendOtpReducer } from '../screen/login/reducer'
import { UserConnectionIdReducer } from '../components/header/reducer';
import { AstroConnectionIdReducer } from '../screen/astrologers/reducer';
import { CallEventReucer } from '../screen/video_call/reducer';
import { WalletBalanceReducer } from '../screen/wallet/reducer';
import { CreateCustomerReducer } from '../screen/wallet/reducer';
import { CreateOrderReducer } from '../screen/wallet/reducer';
import { UserEligibilityReducer } from '../screen/astrologers/reducer';
import { BlockedAstrologersReducer } from '../screen/admin/createAstrologer/reducer';
import { InactiveAstrologersReducer } from '../screen/admin/createAstrologer/reducer';
import { CreateTestimonialsReducer } from '../screen/admin/testimonial_manage/reducer';
import { BannerListReducer } from '../screen/admin/banner_manage/reducer';
import { AdspaceListReducer } from '../screen/admin/banner_manage/reducer';
import { WalletHistoryReducer } from '../screen/wallet/reducer';
import { RatingReviewReducer } from '../screen/astrologers/reducer';
import { AstroCallInfoReducer } from '../components/sideNavigation/reducer';
import { PackageListReducer } from '../screen/wallet/reducer';
import { PackageDetailReducer } from '../screen/admin/packages/reducer';
import { AstrologerCallHistoryReducer } from '../screen/astrologer_dashboard/calls/reducer';
import { UserCallHistoryReducer}  from '../screen/user/profile/reducer';
import { AdminNotificationListReducer } from '../screen/admin/notifications/reducer';
import { CustomerListReducer } from '../screen/admin/customers/reducer';
import { onlineAstrologersReducers } from '../components/sideNavigation/reducer';
import { callsListReducer } from '../screen/admin/callsList/reducer';
import { downloadCallsListReducer } from '../screen/admin/callsList/reducer';
import { missedCallsListReducer } from '../screen/admin/callsList/reducer';
import { liveAstrologersReducer } from '../screen/astrologer_dashboard/liveSession/reducer';
import { popupListReducer } from '../screen/PublicScreens/Home/reducer';
const RootReducer = combineReducers({
    userDataReducer,
    signUpDataReducer,
    toggleSideNavReducer,
    categoriesDataReducer,
    viewCategoriesReducer,
    astrologersDataReducer,
    userPrivacyDataReducer,
    userTermsDataReducer,
    astrologerDetailDataReducer,
    sunsignDataReducer,
    DailyHoroscopeDataReducer,
    NewsDataReducer,
    NewsDetailReducer,
    TestimonialsReducer,
    LanguagesReducer,
    SendOtpReducer,
    AstroVisibilityReducer,
    UserConnectionIdReducer,
    AstroConnectionIdReducer,
    CallEventReucer,
    WalletBalanceReducer,
    CreateCustomerReducer,
    CreateOrderReducer,
    UserEligibilityReducer,
    InactiveAstrologersReducer,
    BlockedAstrologersReducer,
    CreateTestimonialsReducer,
    BannerListReducer,
    AdspaceListReducer,
    WalletHistoryReducer,
    RatingReviewReducer,
    AstroCallInfoReducer,
    PackageListReducer,
    PackageDetailReducer,
    AstrologerCallHistoryReducer,
    UserCallHistoryReducer,
    AdminNotificationListReducer,
    CustomerListReducer,
    onlineAstrologersReducers,
    callsListReducer,
    downloadCallsListReducer,
    missedCallsListReducer,
    liveAstrologersReducer,
    popupListReducer
    // editableComponentReducer
})
export default RootReducer