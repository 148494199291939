import Utils from "../../Utils";


export const saveConnectionInfo = (
    
    values: any,
    // setSubmitting: Function,
    // navigate: any,
) => {
    return (dispatch: any) => {
        Utils.api.postApiCallAkj(
            
            `${Utils.endPoints.save_connection_info}?connection_id=${values}`,
            values,
            (respData: any) => {
                // setSubmitting(true)
                let { data } = respData;
                // console.log(respData)
              
                // if (respData.status === 200) {
                //     Utils.showAlert(1, data.detail);
                //     // navigate(Utils.Pathname.verifyOtp)
                // } else {
                //     Utils.showAlert(2, data.detail);
                // }
                // setSubmitting(false)
            },
            (error: any) => {
                // setSubmitting(false)
                let { data } = error;
                Utils.showAlert(2, data.detail);
            }
        );
    };
};

