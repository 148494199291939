import {UserTermsModal} from '../../../../modal';
import Utils from '../../../../Utils';

const {VIEW_TERMS_DATA} = Utils.ActionName;

export const userTermsDataReducer = (
    state: UserTermsModal = new UserTermsModal(),
    action: any
) => {
    switch (action.type) {
        case VIEW_TERMS_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}