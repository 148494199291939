

import { CreateCustomerModel, WalletBalanceModel, CreateOrderModel, AddAmountModel, WalletHistoryModel, PackageListModel } from '../../modal';
import Utils from '../../Utils';
const { WALLET_BALANCE, CUSTOMER, ORDER, WALLET_HISTORY, PACKAGE_LIST } = Utils.ActionName
export const WalletBalanceReducer = (
    state: WalletBalanceModel = new WalletBalanceModel(),
    action: any
) => {
    switch (action.type) {
        case WALLET_BALANCE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const CreateCustomerReducer = (
    state: CreateCustomerModel = new CreateCustomerModel(),
    action: any
) => {
    switch (action.type) {
        case CUSTOMER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const CreateOrderReducer = (
    state: CreateOrderModel = new CreateOrderModel(),
    action: any
) => {
    switch (action.type) {
        case ORDER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const WalletHistoryReducer = (
    state: WalletHistoryModel = new WalletHistoryModel(),
    action: any
) => {
    switch (action.type) {
        case WALLET_HISTORY:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const PackageListReducer = (
    state: PackageListModel = new PackageListModel(),
    action: any
) => {
    switch (action.type) {
        case PACKAGE_LIST:
            return {...state, ...action.payload};
        default:
            return state;
    }
}
