const LocalImages = {
  FB_LOGO: "../images/fb.png",
  Facebook: "../images/facebook.svg",
  Google_LOGO: "../images/google.svg",
  LeftDivImg: "../images/leftImg.jpg",
  LogoImg: "../images/logo.png",
  LOGO: "https://kds-statics-app-data.s3.amazonaws.com/test/logo.svg",
  DASHBOARD_ACTIVE: "../images/dashboard_white.svg",
  DASHBOARD_INACTIVE: "../images/dashboard_black.svg",
  GROUP: "../images/Group.png",
  NOTIFICATION: "../images/notification.svg",
  FAVORITE_LOGO_ACTIVE: "../images/fav_light.svg",
  FAVORITE_LOGO_INACTIVE: "../images/fav_dark.svg",
  SETTINGS_LOGO_ACTIVE: "../images/settings_light.svg",
  SETTINGS_LOGO_INACTIVE: "../images/settings_dark.svg",
  TEAMS_LOGO_ACTIVE: "../images/teams_light.svg",
  TEAMS_LOGO_INACTIVE: "../images/teams_dark.svg",
  TEMPLATES_LOGO_ACTIVE: "../images/template_light.svg",
  TEMPLATES_LOGO_INACTIVE: "../images/template_dark.svg",
  BRANDLOGO:'../images/brandlogo.svg',
  BRANDNAME:'../images/brandname.svg',
  COLORWHEEL: '../images/color-wheel.svg',
  BOTTOMTEXTICON: '../images/bottomTextIcon.svg',
  BOTTOMCOLORICON: '../images/BottomColorIcon.svg',
  BOTTOMIMAGEICON: '../images/BottomImageIcon.svg',
  BOTTOMDELETEICON: '../images/bottomDeleteIcon.svg',
  PHOTO_CAMERA_BLACK: '../images/photo_camera_black.svg',
  PLAY_STORE_LOGO: '../../images/play-store-logo.svg',
  // ZODIAC: "../../images/zodiac.png",
  ZODIAC: "https://kds-statics-app-data.s3.amazonaws.com/aapkajyotish/signup_banner/signup_banner.png",
  PLACEHOLDER: "../../images/placeholder.png",
  USER: "../../images/user.png",

  HOME_BANNER: "https://kds-statics-app-data.s3.amazonaws.com/test/home_banner.jpg",
  HOME_BANNER_OVER: "https://kds-statics-app-data.s3.amazonaws.com/test/hand_bg.png",
  PAGE_TOP: "https://kds-statics-app-data.s3.amazonaws.com/test/breadcrumb_bg.jpg",

  CALL_CENTER: "../../images/why-choose-icons/call-center.png",
  EXPERT: "../../images/why-choose-icons/expert.png",
  PRIVACY: "../../images/why-choose-icons/privacy.png",
  SOLUTION: "../../images/why-choose-icons/solution.png",
  WORLDWIDE: "../../images/why-choose-icons/worldwide.png",
  COSTUMER: "../../images/why-choose-icons/costumer.png",
  WHY_CHOOSE_BG: "../../images/why-choose-icons/why-choose-bg.svg",

  FACEBOOK: "../../images/facebook.svg",
  INSTAGRAM: "../../images/instagram.svg",
  LINKEDIN: "../../images/linked-in.svg",
  TWITTER: "../../images/twitter.svg",
  YOUTUBE: "../../images/youtube.svg",

  UNDERLINE: "../../images/underline.svg",
  MIDDLE_BANNER: "../../images/add-space-bg.png",
  MOBILE_MENU: "../../images/mobile_menu.svg",
  CALLER_TONE: '../../caller_tone.mp3',
  BUSY_ON_CALL: 'https://kds-statics-app-data.s3.amazonaws.com/aapkajyotish/images/busy.png'
};

export default LocalImages;