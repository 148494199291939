import { AstrologerCallHistoryModel } from '../../../modal'
import Utils from '../../../Utils'
const { ASTRO_CALL_HISTORY } = Utils.ActionName
export const AstrologerCallHistoryReducer = (
    state: AstrologerCallHistoryModel = new AstrologerCallHistoryModel(),
    action: any
) => {
    switch (action.type) {
        case ASTRO_CALL_HISTORY:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
