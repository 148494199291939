import React from "react";
import { Button, FormControl, Icon, Link, MenuItem, Select, SelectChangeEvent, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import LocalImages from "../../Utils/images";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReducersModal } from "../../modal";
import Utils from "../../Utils";
import {adminNavigation, astrologerNavigation} from '../../Utils/constData';
import { getVisibility, updateAstrologerVisibility } from "./action";
import Socket from "../header/socket";
import Incoming from "../header/incoming";
import AstroCalling from "../../screen/astrologer_dashboard/calling/astro-calling";
import AstroSocket from "../../screen/astrologer_dashboard/calling/astro-socket";
import IncomingView from '../../screen/astrologer_dashboard/calling/incoming-screen'
import AstroFeedback from "../../screen/astrologer_dashboard/feedback/feedback";
import { RealtimeClient } from "@supabase/realtime-js";
import { getUsersData } from "../../screen/user/profile/action";
import { createClient } from "@supabase/supabase-js";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sideNavWrapper: {

    },
    topHeader: {
      width: '100%',
      padding: '10px',
      position: 'fixed',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      top: 0,
      left: 0,
      minHeight: '70px',
      backgroundColor: '#143636',
      background: 'linear-gradient(90deg, rgb(242 194 98) 0%, #e65100 100%)',
      borderBottom: '1px solid #F2C262',
      zIndex: 99
    },
    sideNavigation: {
      width: '280px',
      padding: '80px 0 10px',
      position: 'fixed',
      backgroundColor: '#F2C262',
      top: 0,
      left: 0,
      height: '100vh',
      borderRight: '1px solid #F2C262',
      "& ul":{
        height: 'calc(100vh - 100px)',
        overflow: 'auto',
        "& li":{
          padding: '15px',
          borderBottom: '1px solid #efaa4d',
          "& a":{
            color: '#ffffff',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            "& span": {
              marginLeft: '10px'
            }
          }
        }
      }
    },
    activeLink: {
      backgroundColor: '#ed9137'
    },
    logout: {
      display: 'flex',
      alignItems: 'center',
      "& button" : {
        marginLeft: '20px'
      }
    },
    logo: {
      "& a":{
        display: 'flex',
        alignItems: 'center',
        fontWeight: 400,
        fontSize: '22px',
        lineHeight: '1.2',
        color: '#000000',
        "& img": {
          margin: '0 15px 0 0'
        }
      }
  },
  callRwapper: {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 9999
  }
  })
);

 
function SideNavigation() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPage = window.location.pathname;
  const userRole = localStorage.getItem('role_id');
  const [currentStatus, setCurrentStatus] = React.useState('dnd');
  const { data : astroData} = useSelector((state: ReducersModal) => state.userDataReducer);
  // console.log("astroData", astroData);

  React.useEffect(() => {
    dispatch(getUsersData());
}, []);
  
  const new_location = useLocation();
// console.log("NEW LOCATION", new_location.pathname.split('/'));

// console.log("astroData", astroData)
React.useEffect(() => {
  const loc_array = new_location.pathname.split('/');
if(!loc_array.includes('astrologer') && userRole === '3'){
  navigate('/astrologer/dashboard')
  // console.log("condition 3")
}
else if(loc_array.includes('astrologer') && userRole !== '3'){
  navigate('/home')
  // console.log("condition 2")
}
else if(!loc_array.includes('admin') && userRole === '1'){
  navigate('/admin/dashboard  ')
  // console.log("condition 1")
}
}, [new_location])
 

  const { toggle } = useSelector(
    (state: ReducersModal) => state.toggleSideNavReducer
  );

  React.useEffect(() => {
    if(userRole === '3'){
      dispatch(getVisibility());
    }
   
    // setCurrentStatus(data.visibilty)
}, []);

// React.useEffect(() => {
//   dispatch({
//     type: Utils.ActionName.ONLINE_ASTROLOGERS,
//           payload: { online_astrologers: astroData },
//   });
// }, [astroData])
  const {data} = useSelector(
    (state: ReducersModal) => state.AstroVisibilityReducer
  );

  
  // console.log("visibility", data.visibilty);
  

  const [pathName, setPathName] = React.useState("");
  React.useEffect(() => {
    setPathName(window.location.pathname.split("/")[1]);
  }, []);
  const changeRoute = (path: string) => {
    navigate(`${path}`);
    setPathName(window.location.pathname.split("/")[1]);
  };

  const logOutHandelM = () => {
    localStorage.clear();
    window.location.reload();
}




const changeVisibility = (event: SelectChangeEvent) => {
  setCurrentStatus(event.target.value as string);
  dispatch(updateAstrologerVisibility(
      event.target.value as string
  ));
 setTimeout(() => {
  dispatch(getVisibility());
 }, 100);
}
// ===================SOCKET=====================
const [astroSocketResponse, setAstroSocketResponse] = React.useState<any[]>([]);
const [msgType, setMsgType] = React.useState('');
const [astroConId, setAstroConId] = React.useState('');
const [socketStatus, setSocketStatus] = React.useState(false);
const [astroResp, setAstroResp] = React.useState('');
const [viewIncoming, setViewIncoming] = React.useState(false);
const [renderState, setRenderState] = React.useState(0);
const [caller_id, setCaller_id] = React.useState('');
const [showFeedback, setShowFeedback] = React.useState(false);
const [startTime, setStartTime] = React.useState('');
const [endTime, setEndTime] = React.useState('');

const socketResponse = (socketResp: any) => {
// console.log("ASTROLOGER SOCKET RESPONSE --", socketResp);
// console.log('SOCKET CONNECTION STATUS', socketStatus)
setAstroSocketResponse(socketResp);
setMsgType('');
setMsgType(socketResp && socketResp.msg);
setAstroConId(socketResp && socketResp.sender_connectionId);

}

React.useEffect(() => {
  // console.log('1111', msgType)
if(msgType && msgType.split('_')[0] === 'VIDEO'){
  setViewIncoming(true);
  localStorage.setItem('incoming', 'true');
  setCaller_id(msgType && msgType.split('_')[2]);
}


if(msgType === 'CALL_END'){
  setViewIncoming(false);
}
}, [astroSocketResponse]);



// console.log("caller_id", caller_id)
// console.log("VIEWINCOMING", viewIncoming );

const socketConnection = (socketValue: any) => {
  setSocketStatus(socketValue)
}
// const [msg, ConnectionId, sender_connectionId] = astroSocketResponse;

// console.log('MESSAGE', astroSocketResponse);
// console.log(`MESSAGE:- ${msgType && msgType.split('_')[0]} | ASTROLOGER CON ID:- ${astroConId}`);


// console.log(msgType && msgType.split('_'))
const callResponse = (callResp: any) => {
  setAstroResp(callResp);
  setViewIncoming(false);
  // localStorage.setItem('incoming', 'false')
  // console.warn('INCOMING VIEW RESPONSE', callResp);
  

}

const roomExit = (roomExit_value: any) => {
  // setShowFeedback(true);
  setTimeout(() => {
    // setAstroResp('');
    setAstroResp(roomExit_value);
  }, 1000);
}

// console.log('CALL END', astroResp);


React.useEffect(() => {
  dispatch({
    payload: {
      callInfo :  msgType && msgType.split('_')[1]
    },
    type: Utils.ActionName.ASTRO_CALL_INFO
})
}, [msgType])

const call_status = localStorage.getItem("callStatus");

React.useEffect(() => {
     if(call_status === 'completed'){
         setShowFeedback(true)
     }
}, []);

const [callUId, setCallUId] = React.useState<any>('');
const call_UID = localStorage.getItem("callUID");

   React.useEffect(() => {
    if(call_UID !== ''){
      setCallUId(call_UID)
    }
    
}, [call_UID]);
// ===================SOCKET=====================

  return (
    <div className={classes.sideNavWrapper}>
 {/* <AstroCalling roomId = {'abcd'}/> */}
      <div className={classes.topHeader}>
        <div className={classes.logo}>
          <Link href="#" underline="none">
            <img src={LocalImages.LOGO} alt="AKJ" width={60} />
            <p>aapkajyotish</p>
          </Link>
        </div>
        <div className={classes.logout}>
          {
            userRole && userRole === '3' ?
              // <Socket/>
              <AstroSocket 
              socketResponse={socketResponse} 
              conStatus={socketConnection} 
              astroResponse={astroResp}
              senderId={astroConId}
              renderState={renderState}
              />
              : null
          }
          {
            userRole && userRole === '3' && viewIncoming ?
            <IncomingView isMic={false} isCamera={false} callResponse={callResponse} userInfo={astroSocketResponse}/> : null
          }

          {
            userRole && userRole === '3' && astroResp === 'ACCEPT' ?
           <div className={classes.callRwapper}><AstroCalling callerId={caller_id} roomId = {msgType && msgType.split('_')[1]} roomExit={roomExit}/></div>  : null
          }
          {
            userRole == '3' ?
              <FormControl size="small">
                {
                  data && data.visibilty != undefined ?
                
                <Select
                  id="demo-simple-select"
                  value={data.visibilty}
                  onChange={changeVisibility}
                 sx={{
                  backgroundColor: '#ffffff',
                  fontSize: '0.85rem',
                  fontWeight: 800,
                  color: `${data.visibilty == 'online' ? 'green' : data.visibilty == 'offline' ? 'orange' : 'red'}`
                 }}
                >
                  <MenuItem value={'offline'}>Offline</MenuItem>
                  <MenuItem value={'online'}>Online</MenuItem>
                  <MenuItem value={'dnd'}>DND</MenuItem>
                </Select> : 'Loading'
}
              </FormControl> : ''
          }
          <Button onClick={() => { logOutHandelM() }} variant="outlined" sx={{ color: '#ffffff' }}>Logout</Button>
        </div>
      </div>
      <div className={classes.sideNavigation}>
        {
          userRole && userRole == '1' ?
            <ul>
              {
                adminNavigation.map((values: any, index: any) => {
                  return (
                    <li key={index} className={currentPage == values.path ? `${classes.activeLink}` : ''}>
                      <a onClick={() => changeRoute(values.path)} >
                        <Icon>{values.icon}</Icon>
                        <span>{values.name}</span>
                      </a>
                    </li>
                  )
                })
              }
            </ul> :
            <ul>
              {
                astrologerNavigation.map((values: any, index: any) => {
                  return (
                    <li key={index} className={currentPage == values.path ? `${classes.activeLink}` : ''}>
                      <a onClick={() => changeRoute(values.path)} >
                        <Icon>{values.icon}</Icon>
                        <span>{values.name}</span>
                      </a>
                    </li>
                  )
                })
              }

            </ul>
        }
 
      </div>
        {/* {
          userRole && userRole === '3' ?
          <AstroFeedback
                user_id = {astroData && astroData.id}
                call_uid = {callUId && callUId}
                dialogState = {showFeedback}
                closeFeedbackDialog = {setShowFeedback}
            /> : null
        } */}
    </div>
  );
}

export default SideNavigation;
