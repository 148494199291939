import LocalImages from "./images"
interface UserNavigation {
  name: string;
  path: string;
  active: string;
  activeIcon: any;
  inactiveIcon: any;
}

interface AdminNavigation {
  name: string;
  path: string;
  icon: any;
}

interface BottomNavigation {
  name: string;
  path: string;
}

interface Languages {
  name: string;
  value: number
}

interface Category {
  name: string;
  value: number
}
interface ThemeColors {
  color: string;
}

interface WhyChooseUs {
  title: string;
  icon: string
}

interface SocialIcons {
  name: string;
  icon: string,
  url: string
}

interface Colors {
  color: string
}

export const Languages: Languages[] = [
  {
    name: "Hindi",
    value: 0
  },
  {
    name: "Bangla",
    value: 0
  },
  {
    name: "English",
    value: 0
  },
  {
    name: "Gujarati",
    value: 0
  },
  {
    name: "Kannada",
    value: 0
  },
  {
    name: "Marathi",
    value: 0
  },
  {
    name: "Tamil",
    value: 0
  },
  {
    name: "Telugu",
    value: 0
  },
  {
    name: "Punjabi",
    value: 0
  },
  {
    name: "Malayalam",
    value: 0
  },
  {
    name: "Marwari",
    value: 0
  }
]

export const Categories: Category[] = [
  {
    name: "Vedic",
    value: 0
  },
  {
    name: "Tarot",
    value: 1
  },
  {
    name: "Numerology",
    value: 2
  },
  {
    name: "Vastu",
    value: 3
  },
  {
    name: "Fengshui",
    value: 4
  },
  {
    name: "KP",
    value: 5
  },
  {
    name: "Prashna",
    value: 6
  },
  {
    name: "Reiki Healing",
    value: 7
  },
  {
    name: "Yoga & Meditation",
    value: 8
  },
  {
    name: "Pranic Healing",
    value: 9
  },
  {
    name: "Physics",
    value: 10
  }
]

export const UserNavigation: UserNavigation[] = [
  {
    name: "Home",
    path: "/home",
    active: "home",
    activeIcon: LocalImages.DASHBOARD_ACTIVE,
    inactiveIcon: LocalImages.DASHBOARD_INACTIVE
  },

  {
    name: "Astrologers",
    path: "/astrologers",
    active: "astrologers",
    activeIcon: LocalImages.DASHBOARD_ACTIVE,
    inactiveIcon: LocalImages.DASHBOARD_INACTIVE
  },
  {
    name: "Horoscope",
    path: "/horoscope/1",
    active: "horoscope",
    activeIcon: LocalImages.TEAMS_LOGO_ACTIVE,
    inactiveIcon: LocalImages.TEAMS_LOGO_INACTIVE
  },
  {
    name: "Articles",
    path: "/articles",
    active: "articles",
    activeIcon: LocalImages.TEMPLATES_LOGO_ACTIVE,
    inactiveIcon: LocalImages.TEMPLATES_LOGO_INACTIVE
  },
  {
    name: "About Us",
    path: "/about_us",
    active: "about_us",
    activeIcon: LocalImages.FAVORITE_LOGO_ACTIVE,
    inactiveIcon: LocalImages.FAVORITE_LOGO_INACTIVE
  }
];

export const adminNavigation: AdminNavigation[] = [
  {
    name: 'Categories',
    path: '/admin/categories',
    icon: 'category'
  },
  {
    name: 'Astrologers',
    path: '/admin/astrologers',
    icon: 'connect_without_contact'
  },
  {
    name: 'Customers',
    path: '/admin/customers',
    icon: 'supervisor_account'
  },
  {
    name: 'Calls',
    path: '/admin/calls',
    icon: 'headset_mic'
  },
  {
    name: 'Add Horoscope',
    path: '/admin/horoscope/add',
    icon: 'brightness_7'
  },
  {
    name: 'Atricles',
    path: '/admin/articles',
    icon: 'connect_without_contact'
  },
  {
    name: 'Banner & Offers',
    path: '/admin/banners',
    icon: 'photo_library'
  },
  {
    name: 'Testimonials',
    path: '/admin/testimonials',
    icon: 'reviews'
  },
  // {
  //   name: 'Users',
  //   path: 'admin/users',
  //   icon: 'group'
  // },
  {
    name: 'Packages',
    path: '/admin/packages',
    icon: 'currency_rupee'
  },
  {
    name: 'Notifications',
    path: '/admin/notification',
    icon: 'notifications'
  },
  {
    name: 'Terms & Conditions',
    path: '/admin/terms-conditions',
    icon: 'report'
  },
  {
    name: 'Privacy & Policy',
    path: '/admin/privacy-policy',
    icon: 'policy'
  },

  // {
  //   name: 'Settings',
  //   path: '/admin/settings',
  //   icon: 'settings'
  // },
]

export const astrologerNavigation: AdminNavigation[] = [
  {
    name: 'Dashboard',
    path: '/astrologer/dashboard',
    icon: 'category'
  },
  {
    name: 'Call History',
    path: '/astrologer/calls',
    icon: 'settings_phone'
  },
  // {
  //   name: 'Payments',
  //   path: '/astrologer/payments',
  //   icon: 'currency_rupee'
  // },
  {
    name: 'My Profile',
    path: '/astrologer/profile',
    icon: 'manage_accounts'
  },

  // {
  //   name: 'Settings',
  //   path: '/admin/settings',
  //   icon: 'settings'
  // },
]

export const BottomNavigation: BottomNavigation[] = [
  {
    name: "About Us",
    path: "/about_us"
  },
  {
    name: "Privacy Policy",
    path: "/privacy-and-policy"
  },
  {
    name: "Contact Us",
    path: "/contact"
  },
  {
    name: "Terms & Conditions",
    path: "/terms-and-conditions"
  },
  {
    name: "Astrologers",
    path: "/astrologers"
  },


  // {
  //   name: "Horoscope",
  //   path: ""
  // },
  // {
  //   name: "FAQ",
  //   path: ""
  // },
  // {
  //   name: "I am a Astrologer",
  //   path: ""
  // },
  // {
  //   name: "Career",
  //   path: ""
  // },

  // {
  //   name: "Blogs",
  //   path: ""
  // },


  {
    name: 'Our Disclaimer',
    path: '/disclaimer'
  },
  {
    name: 'Refund and Cancellation Policy',
    path: '/refund-policy'
  },
]

export const PrimaryColors = {
  50: '#fdf8e2',
  100: '#fbedb7',
  200: '#f8e287',
  300: '#f5d657',
  400: '#f3cd33',
  500: '#f1c40f',
  600: '#efbe0d',
  700: '#edb60b',
  800: '#ebaf08',
  900: '#e7a204',
  1100: '#ffffff',
  1200: '#fff3da',
  1400: '#ffe1a7',
  1700: '#ffd98e',
  1800: '#ffffff',
}

export const SecondaryColors = {
  50 : '#ffede1',
    100 : '#ffd3b4',
    200 : '#ffb583',
    300 : '#ff9751',
    400 : '#ff812b',
    500 : '#ff6b06',
    600 : '#ff6305',
    700 : '#ff5804',
    800 : '#ff4e03',
    900 : '#ff3c02',
    1100 : '#ffffff',
    1200 : '#fff4f2',
    1400 : '#ffcabf',
    1700 : '#ffb5a6',
    1800: '#ffffff'
}

export const WhyChooseUs : WhyChooseUs[] = [
  {
    title: '90+ Expert Astrologers',
    icon: LocalImages.EXPERT
  },
  {
    title: '24x7, 365 Days Availability',
    icon: LocalImages.CALL_CENTER
  },
  {
    title: 'Instant Access Worldwide',
    icon: LocalImages.WORLDWIDE
  },
  {
    title: 'Accurate Remedial Solutions',
    icon: LocalImages.SOLUTION
  },
  {
    title: 'Privacy Guaranteed',
    icon: LocalImages.PRIVACY
  },
  {
    title: 'Trusted by million clients',
    icon: LocalImages.COSTUMER
  }
]

export const primary = PrimaryColors[600];
export const secondary = PrimaryColors[500];
export const button_bg = SecondaryColors[400];
export const button_text = SecondaryColors[1200];

export const beforeCallConfirmation = true;
export const showBalancePopup = true;

export const SocialIcons: SocialIcons[] = [
  // {
  //   name: 'Linked In',
  //   icon: LocalImages.LINKEDIN,
  //   url: 'https://www.linkedin.com'
  // },
  {
    name: 'Instagram',
    icon: LocalImages.INSTAGRAM,
    url: 'https://www.instagram.com/aapkajyotish/'
  },
  {
    name: 'Facebook',
    icon: LocalImages.FACEBOOK,
    url: 'https://www.facebook.com/Aapkajyotish-101026852737211'
  },
  {
    name: 'Twitter',
    icon: LocalImages.TWITTER,
    url: 'https://twitter.com/aapka_jyotish'
  },
  {
    name: 'Youtube',
    icon: LocalImages.YOUTUBE,
    url: 'https://bit.ly/aapkajyotish'
  }
]
