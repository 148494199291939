import { number } from "yup";

class LoadingModal {
  isLoading: boolean = false;
}
class UserDataModal {
  data: any = {}
}
class SignUpDataModal {
  data: any = {}
}

class SendOtpModal {
  country_code_v: string = "";
  mobile_v: string = "";
}

class ToggleSidenavModal {
  toggle: boolean = false;
}
class DashboardDataModal {
  buisnessCards: Array<any> = [];
  categories: Array<any> = [];
  flyers: Array<any> = [];
  newTemplates: Array<any> = [];
  popularTemplates: Array<any> = [];
}

class EditableComponentModal {
  customizable_components: any = {};
  // editable: any ={};
  // data: any = {};
}

class CategoriesListModal {
  category_items: any = {};
  CategoriesLisQueryParameters: any = {
    pageNo: 1,
    limit: 50,
    totalPages: 1,
  };
}

class ViewCategoryModal {
  categiryData: any = {}
}

class AstrologersModal {
  items: any = {};
  AstrologersLisQueryParameters: any = {
    pageNo: 1,
    limit: 10,
    totalPages: 1,
    category: ""
  };
}

class UserTermsModal {
  items: any = {};
}

class UserPrivacyModal {
  items: any = {};
}

class AstroDetailModal {
  astroDetailData: any = {};
}

class SunsignModal {
  sunsign_items: any = {};
  SunsignLisQueryParameters: any = {
    pageNo: 1,
    limit: 100,
  };
}

class DailyHoroscopeModal {
  dailyHoroscopes: any = {};
  dailyHoroscopeLisQueryParameters: any = {
    pageNo: 1,
    limit: 12,
  };
}

class NewsModel {
  news_items: any = {};
  newsQueryParameters: any = {
    page: 1,
    limit: 12,
    totalPages: 1
  }
}

class NewsDetailModel {
  newsDetail: any = {}
}

class TestimonialsModel {
  testimonialsData: any = {};
  testimonialQueryParameters: any = {
    page: 1,
    limit: 50,
    totalPages: 1
  }
}

class LanguagesModel {
  languagesData: any = {}
}

class AstroVisibilityModel {
  data: any = {}
}

class UserConnectionIdModel {
  connection_details: any = {}
}

class AstroConnectionIdModel {
  connection_id: any = {}
}

class CallEventModel {
  event: any = {}
}

class WalletBalanceModel {
  wallet_balance: any = {}
}

class AddAmountModel {
  wallet_amount: any = {}
}

class WalletHistoryModel {
  wallet_history: any = {}
}

class CreateCustomerModel {
  customer: any = {}
}

class CreateOrderModel {
  order:any = {}
}

class UserEligibilityModel {
  eligibility: any = {}
}

class InactiveAstrologersModel {
  data: any = {}
}

class BlockedAstrologersModel {
  data: any = {}
}

class CreateTestimonialsModel {
    testimonial_data: any = {
      "name": "",
      "designation": "",
      "content": "",
      "image_url": ""
    }
}

class BannerListModel {
  banner_list: any = {}
}

class PopupListModel {
  popupImages: any = {}
}

class AdspaceListModel {
  adspace: any = {}
}

class RatingReviewModel {
  reviews_list: any = {}
}

class AstroCallInfoModel {
  callInfo: any = {}
}

class PackageListModel {
  package_list: any = {}
}

class PackageDetailModel {
  package_detail: any = {}
}

class UserCallHistoryModel {
  user_call_history: any = {}
}

class AstrologerCallHistoryModel {
  astrologer_call_history: any = {}
}

class AdminNotificationListModel {
  admin_notification_list: any = {}
}

class CustomerListModel {
  customer_list: any = {}
}
class onlineAstrologersModel {
  online_astrologers: any = {}
}

class callsListModel {
  calls_List: any = {}
}

class downloadCallsListModel {
  download_list: any = {}
}

class missedCallsListModel {
  missed_calls_list: any = {}
}

class liveAstrologersModel {
  liveAstrologers: any = {"name": 'aditya'}
}

class ReducersModal {
  globalLoaderReducer: LoadingModal = new LoadingModal();
  userDataReducer: UserDataModal = new UserDataModal();
  signUpDataReducer: SignUpDataModal = new SignUpDataModal();
  toggleSideNavReducer: ToggleSidenavModal = new ToggleSidenavModal();
  dashboardDataReducer: DashboardDataModal = new DashboardDataModal();
  editableComponentReducer: EditableComponentModal = new EditableComponentModal();
  categoriesDataReducer:  CategoriesListModal = new CategoriesListModal();
  viewCategoriesReducer: ViewCategoryModal = new ViewCategoryModal();
  astrologersDataReducer: AstrologersModal = new AstrologersModal();
  userTermsDataReducer: UserTermsModal = new UserTermsModal();
  userPrivacyDataReducer: UserPrivacyModal = new UserPrivacyModal();
  astrologerDetailDataReducer: AstroDetailModal = new AstroDetailModal();
  sunsignDataReducer: SunsignModal = new SunsignModal();
  DailyHoroscopeDataReducer: DailyHoroscopeModal = new DailyHoroscopeModal();
  NewsDataReducer: NewsModel = new NewsModel();
  NewsDetailReducer: NewsDetailModel = new NewsDetailModel();
  TestimonialsReducer: TestimonialsModel = new TestimonialsModel();
  CreateTestimonialsReducer: CreateTestimonialsModel = new CreateTestimonialsModel();
  LanguagesReducer: LanguagesModel = new LanguagesModel();
  SendOtpReducer: SendOtpModal = new SendOtpModal();
  AstroVisibilityReducer: AstroVisibilityModel = new AstroVisibilityModel();
  UserConnectionIdReducer: UserConnectionIdModel = new UserConnectionIdModel();
  AstroConnectionIdReducer: AstroConnectionIdModel = new AstroConnectionIdModel();
  CallEventReucer: CallEventModel = new CallEventModel();
  WalletBalanceReducer: WalletBalanceModel = new WalletBalanceModel();
  AddAmountReducer: AddAmountModel = new AddAmountModel();
  WalletHistoryReducer: WalletHistoryModel = new WalletHistoryModel();
  CreateCustomerReducer: CreateCustomerModel = new CreateCustomerModel();
  CreateOrderReducer: CreateOrderModel = new CreateOrderModel();
  UserEligibilityReducer: UserEligibilityModel = new UserEligibilityModel();
  InactiveAstrologersReducer: InactiveAstrologersModel = new InactiveAstrologersModel();
  BlockedAstrologersReducer: BlockedAstrologersModel = new BlockedAstrologersModel();
  BannerListReducer: BannerListModel = new BannerListModel();
  AdspaceListReducer: AdspaceListModel = new AdspaceListModel();
  RatingReviewReducer: RatingReviewModel = new RatingReviewModel();
  AstroCallInfoReducer: AstroCallInfoModel = new AstroCallInfoModel();
  PackageListReducer: PackageListModel = new PackageListModel();
  PackageDetailReducer: PackageDetailModel = new PackageDetailModel();
  UserCallHistoryReducer: UserCallHistoryModel = new UserCallHistoryModel();
  AstrologerCallHistoryReducer: AstrologerCallHistoryModel = new AstrologerCallHistoryModel();
  AdminNotificationListReducer: AdminNotificationListModel = new AdminNotificationListModel();
  CustomerListReducer: CustomerListModel = new CustomerListModel();
  onlineAstrologersReducers: onlineAstrologersModel = new onlineAstrologersModel();
  callsListReducer: callsListModel = new callsListModel();
  missedCallsListReducer: missedCallsListModel = new missedCallsListModel();
  downloadCallsListReducer: downloadCallsListModel = new downloadCallsListModel();
  liveAstrologersReducer: liveAstrologersModel = new liveAstrologersModel();
  popupListReducer: PopupListModel = new PopupListModel();
}


export {
  ReducersModal,
  UserDataModal,
  SignUpDataModal,
  ToggleSidenavModal,
  DashboardDataModal,
  EditableComponentModal,
  CategoriesListModal,
  ViewCategoryModal,
  AstrologersModal,
  UserTermsModal,
  UserPrivacyModal,
  AstroDetailModal,
  SunsignModal,
  DailyHoroscopeModal,
  NewsModel,
  NewsDetailModel,
  TestimonialsModel,
  LanguagesModel,
  SendOtpModal,
  AstroVisibilityModel,
  UserConnectionIdModel,
  AstroConnectionIdModel,
  CallEventModel,
  WalletBalanceModel,
  CreateCustomerModel,
  CreateOrderModel,
  AddAmountModel,
  UserEligibilityModel,
  InactiveAstrologersModel,
  BlockedAstrologersModel,
  CreateTestimonialsModel,
  BannerListModel,
  AdspaceListModel,
  WalletHistoryModel,
  RatingReviewModel,
  AstroCallInfoModel,
  PackageListModel,
  PackageDetailModel,
  UserCallHistoryModel,
  AstrologerCallHistoryModel,
  AdminNotificationListModel,
  CustomerListModel,
  onlineAstrologersModel,
  callsListModel,
  missedCallsListModel,
  downloadCallsListModel,
  liveAstrologersModel,
  PopupListModel
};
