import React from 'react';
import { useDispatch } from 'react-redux';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { saveConnectionInfo } from '../../../components/header/action';
import LensIcon from '@mui/icons-material/Lens';
import { setInterval } from 'timers';

interface Props {
    socketResponse: Function,
    conStatus: Function,
    astroResponse: any,
    senderId: any,
    renderState: any
}

const AstroSocket: React.FC<Props> = ({
    socketResponse,
    conStatus,
    astroResponse,
    senderId,
    renderState
}) => {
    const dispatch = useDispatch();
    const access_token = localStorage.getItem("access_token");
 // ================================ SOCKET =============================

 const [socketUrl, setSocketUrl] = React.useState('wss://s1lsj26jc3.execute-api.ap-south-1.amazonaws.com/production');
 const [messageHistory, setMessageHistory] = React.useState<any[]>([]);
 const [socketTimer, setSocketTimer] = React.useState(0);

//  setInterval(() => {
//     setSocketTimer(socketTimer + 1)
//  }, 10000);

 const { sendMessage, lastMessage, readyState, sendJsonMessage, lastJsonMessage } = useWebSocket(socketUrl, {
    shouldReconnect : (event: WebSocketEventMap['close']) => true,
  reconnectInterval : 1000

 });
 
 const handleGetConnection = React.useCallback(() => sendJsonMessage(
     {"action": "getconnection", "message": "TO GET CONNECTION ID"}
     ), [])
 
 const connectionStatus = {
     [ReadyState.CONNECTING]: 'Connecting',
     [ReadyState.OPEN]: 'Open',
     [ReadyState.CLOSING]: 'Closing',
     [ReadyState.CLOSED]: 'Closed',
     [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
 }[readyState];
 
 
 React.useEffect(() => {
        handleGetConnection();
  
 }, []);
 
 React.useEffect(() => {
    conStatus(connectionStatus)
 }, [connectionStatus]);

 React.useEffect(() => {
    socketResponse('')
    socketResponse(lastJsonMessage)
   
 }, [lastJsonMessage]);
 
 if(lastJsonMessage !== undefined && lastJsonMessage !== null && access_token !== null ){
    // console.log(lastJsonMessage.ConnectionId);
    dispatch( saveConnectionInfo(lastJsonMessage.ConnectionId))
  }


//   React.useEffect(() => {
//     if(lastJsonMessage !== undefined && lastJsonMessage !== null && access_token !== null ){
//         console.warn("NEW API UPDATE");
//         dispatch( saveConnectionInfo(lastJsonMessage.ConnectionId))
//       }
//   }, [connectionStatus])

setInterval(() => {
window.location.reload()
}, 600000)
//  console.warn(lastJsonMessage);
 if(connectionStatus === 'Open'){
 }

 React.useEffect(() => {
    if(astroResponse != '' && astroResponse === 'ACCEPT' ){
        // console.log("=============", astroResponse, senderId)
        sendJsonMessage(
            {"action": "message", "message": "ACCEPT", "receiver": senderId}
        )
    }
    if(astroResponse != '' && astroResponse === 'REJECT' ){
        // console.log("=============", astroResponse, senderId)
        sendJsonMessage(
            {"action": "message", "message": "REJECT", "receiver": senderId}
        )
    }
    if(astroResponse != '' && astroResponse === 'CALL_END' ){
        // console.log("=============", astroResponse, senderId)
        sendJsonMessage(
            {"action": "message", "message": "CALL_END", "receiver": senderId}
        )
    }
    if(astroResponse != '' && astroResponse === 'NORESPONSE' ){
        // console.log("=============", astroResponse, senderId)
        sendJsonMessage(
            {"action": "message", "message": "NORESPONSE", "receiver": senderId}
        )
    }
 }, [astroResponse])
 // ================================ SOCKET =============================
    return (
        <React.Fragment>
            <LensIcon sx={{color: `${connectionStatus === 'Open' ? 'green' : 'red'}`}}/> &nbsp;&nbsp;
             {/* {
                lastJsonMessage && lastJsonMessage.ConnectionId ? <p>{lastJsonMessage.ConnectionId}</p> : " no data"
            }
            <p>The WebSocket is currently:-- {socketTimer} <b>{connectionStatus} </b></p> */}
        </React.Fragment>
    )
}

export default AstroSocket;