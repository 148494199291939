import "./App.css";
import Routers from "./router";
import React from "react";
import { ToastContainer } from 'react-toastify';
import { useSelector } from "react-redux";

function App() {
  const account = useSelector((state) => state );
  console.warn(account)
  return (
    <div className="App">
      <ToastContainer/>
      <Routers />
    </div>
  );
}

export default App;
