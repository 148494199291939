const endPoint = {
    login:'/login',
    send_otp: '/send_otp',
    adminLogin: 'admin/login',
    forgotPassword:'/forget-password',
    resendOtp:"/resend-otp",
    verifyOTP:"verify_otp",
    resetPassword:'/reset-password',
    signUp:"register",
    home:'/home',
    categories: 'categories',
    addCategory: 'category',
    viewCategory: 'categories',
    editCategory: 'category/',
    astrologers: 'astrologer',
    viewPrivacyData: 'page/privacy',
    viewTermsData: 'page/terms',
    userProfileData: 'user/me',
    updateProfileDta: 'update-profile',
    astrologerDetail: 'astrologer-detail/',
    sunsignData: 'sunsigns/',
    DailyHoroscopeData: 'daily_horoscope/today/',
    newsData: 'blog',
    newsDetail: 'blog/',
    testimonials: 'testimonials',
    languages: 'languages',
    logOut: 'logout',
    zegoToken: 'generate-token',
    setAstrologerVisibility: 'astrologer/set-visibilty',
    streamId: 'get_guest_stream_id',
    zegi_uuid: 'fetch-zego-uuid',
    astro_visibility: 'astrologer/check-visibilty',
    socket: 'websocket',
    change_astro_status: 'admin/change-astrologer-status',
     // ---------------- socket path --------------;
     save_connection_info: 'save-connection-info',
     get_connection_info: 'fetch-connection-info',
    //  ================= payment ============
    get_wallet_balance: 'get-wallet-balance',
    add_amount: 'update-order',
    get_wallet_history: 'get-wallet-history',
    create_customer: 'create-customer',
    create_order: 'create-order',

    call_feedback: 'save-call-feedback',
    user_feedback: 'rate-user',
    save_call_info: 'save-call-info',
    call_check_eliglibilty: 'call-check-eliglibilty',

    create_astrologer: "admin/create-astrologer",
    create_blogs: "blog",
    edit_blog: "blog",
    edit_astrologers: "admin/edit-astrologer",

    getTestamonials: "testimonials",
    createTestamonials: "testimonial",
    editTestamonials: "testimonial",
    deleteTestamonials: "testimonial",

    getBanner: "banner",
    createBanner: "banner",
    deleteBanner: "banner",

    reviews_list: 'astrologer-reviews',

    getPackage: 'package',
    addPackage: 'package',
    editPackage: 'package',
    deletePackage: 'package',
    packageDetail: 'package',
    
    userCallHistory: 'user-call-history',
    astroCallHistory: 'astrologer-call-history',
    notificationList: 'admin/admin-notification-list',
    sendNotification: 'admin/create-notification',
    updateAstrologerRating: 'rate-user',
    customerList: 'admin/customer-list',
    addAmountByAdmin: 'admin/add-amount',
    callsList: 'admin/video-calls-list',
    downloadVideoCalls: 'admin/download-video-call',
    downloadMissedCalls: 'admin/download-missed-call-list',
    downloadCustomerList: 'admin/download-customer-list',
    missedCallsList: 'admin/missed-call-list',
    addHoroscope: 'daily_horoscope'
};
export default endPoint;
