import Utils from "../../../Utils";

export const saveCallOnfo = (
    values: object,
    // setSubmitting: Function,
    
  ) => {
    return (dispatch: any) => {
      const dataToSend = values;
      // console.log(values)
      Utils.api.postApiCallAkj(
        Utils.endPoints.save_call_info,
        dataToSend,
        (responseData: any) => {
          const { data } = responseData;
          // console.log(responseData)
          if (responseData.status === 200) {
            //   alert(data.message)
            // Utils.showAlert(1, "data submit");
          } else {
            Utils.showAlert(2, data.message);
          }
        //   setSubmitting(false);
        },
        (error: any) => {
        //   setSubmitting(false);
          let { data } = error;
          Utils.showAlert(2, data.message);
        }
      );
    };
  };

  export const updateAstrologerVisibility = (
    values: string,
    
) => {
    return (dispatch: any) => {
        const dataToSend = {
            values
        };
        Utils.api.putApiCallAkj(
            `${Utils.endPoints.setAstrologerVisibility}?visibilty=${values}`,
            {},
            (responseData: any) => {
                const { data } = responseData;
                // console.log("updated data VISLBILITY==================", responseData)
                if (responseData.status === 200) {
                    Utils.showAlert(1, "Visibility Updated");
                   
                } else {
                    Utils.showAlert(2, "Something went wrong");
                }
            },
            (error: any) => {
                
                let { data } = error;
                Utils.showAlert(2, "Something went wrong");
            }
        );
    };
}