import React, { lazy } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import PublicRoute from "../hoc/publicRoute/index";
import PrivateRoute from "../hoc/privateRoute/index";
import Utils from "../Utils";
// import Home from "../screen/PublicScreens/Home";
const {
  SignUp,
  Login,
  AdminLogin,
  verifyOtp,
  Dashboard,
  settings,
  Home,
  Categories,
  Astrologers,
  Privacy,
  Terms,
  UserPrivacy,
  UserTerms,
  UserProfile,
  EditProfile,
  ContactUs,
  AstrologerDetails,
  AboutUs,
  Disclaimer,
  RefundPolicy,
  AstrologersList,
  AstrologerDetail,
  AddAstrologer,
  Horoscope,
  News,
  News_detail,
  connect,
  wallet,
  astro_dashboard,
  astro_calls,
  astro_payments,
  astro_profile,
  astro_edit_profile,
  // userCallFeedback,
  // astro_feedback,
  InactiveAstrologer,
  BlockedAstrologer,
  ManageBlogs,
  Testimonials,
  Banner,
  transactions,
  Packages,
  userCallHistory,
  Notification,
  admin_customers,
  CallsList,
  MissedCallsList,
  AddHoroscope,
  live,
  live_session
} = Utils.Pathname;
const LandingPage = lazy(() => import("../screen/index"));
const SignUpScreen = lazy(() => import("../screen/signup"));
const LoginScreen = lazy(() => import("../screen/login"));
const VerifyOtpScreen = lazy(() => import("../screen/login/verifyOtp"))

const DashboardScreen = lazy(() => import("../screen/dashboard"));
const SettingsScreen = lazy(() => import("../screen/settings"));
// const VideoEditorScreen = lazy(() => import("../screen/videoEditor"));
const HomeScreen = lazy(() => import("../screen/PublicScreens/Home"));
const UserPrivacyPage = lazy(() => import("../screen/PublicScreens/cms/userPrivacy"));
const UserTermsPage = lazy(() => import("../screen/PublicScreens/cms/userTerms"));
const ContactUsPage = lazy(() => import("../screen/PublicScreens/contactUs"));
const AboutUsPage = lazy(() => import("../screen/PublicScreens/AboutUs"));
const DisclaimerPage = lazy(() => import("../screen/PublicScreens/cms/disclaimer"));
const RefundPolicyPage = lazy(() => import("../screen/PublicScreens/cms/refund"));
// const UserFeedbackPage = lazy(() => import("../screen/user/feedback/feedback"));
//=================user routes ====================
const AstrologersPage = lazy(() => import("../screen/astrologers"));
const AstrologerDetailsPage = lazy(() => import("../screen/astrologers/detail"));
const UserProfileScreen = lazy(() => import("../screen/user/profile/index"));
const EditUserProfileScreen = lazy(() => import("../screen/user/profile/editProfile"));
const UserCallHistoryScreen = lazy(() => import("../screen/user/profile/callHistory"));
// ========= Admin Routs ==================
const AdminLoginPage = lazy(() => import("../screen/login/adminLogin"))
const CategoriesPage = lazy(() => import("../screen/admin/categories"));
const PrivacyPage = lazy(() => import("../screen/admin/cms/privacy"));
const TermsPage = lazy(() => import("../screen/admin/cms/terms/"));
const AstrologersListPage = lazy(() => import("../screen/admin/createAstrologer"));
const PackagesPage = lazy(() => import("../screen/admin/packages"));
// const AstrologerDetailPage = lazy(() => import("../screen/admin/createAstrologer/"));
// const AddAstrologerPage = lazy(() => import("../screen/admin/createAstrologer/addAstrologer"));
const HoroscopePage = lazy(() => import("../screen/horoscope"));
const NewsPage = lazy(() => import("../screen/PublicScreens/News"));
const News_detail_page = lazy(() => import("../screen/PublicScreens/News/detail"));
const Wallet_page = lazy(() => import("../screen/wallet"))
const Astro_Dashboard_Page = lazy(() => import("../screen/astrologer_dashboard/dashboard"));
const Astro_Calls_Page = lazy(() => import("../screen/astrologer_dashboard/calls"));
const Astro_Payments_Page = lazy(() => import("../screen/astrologer_dashboard/payments"));
const Astro_Profile_Page = lazy(() => import("../screen/astrologer_dashboard/profile"));
const Astro_Profile_Edit_Page = lazy(() => import("../screen/astrologer_dashboard/profile/editAstroProfile"));
// const Astro_Feedback_Page = lazy(() => import('../screen/astrologer_dashboard/feedback/feedback'));
const InactiveAstrologerPage = lazy(() => import("../screen/admin/createAstrologer/incativeAstrologers"));
const BlockedAstrologerPage = lazy(() => import("../screen/admin/createAstrologer/blockedAstrologer"));
const ManageArticlePage = lazy(() => import("../screen/admin/blogs"));
const ManageTestimonialsPage = lazy(() => import("../screen/admin/testimonial_manage"));
const BannerListPage = lazy(() => import('../screen/admin/banner_manage'));
const NotificationPage = lazy(() => import('../screen/admin/notifications'));
const UserTransactiobsPage = lazy(() => import("../screen/wallet/transactions"));
const AdminCustomerPage = lazy(() => import("../screen/admin/customers"));
const CallsListPage = lazy(() => import("../screen/admin/callsList"));
const MissedCallsPage = lazy(() => import("../screen/admin/callsList/missedCalls"));
const AddHoroscopePage = lazy(() => import("../screen/admin/horoscope"));

const LiveStreaming = lazy(() => import('../screen/liveStreaming'));
const LiveSession = lazy(() => import('../screen/astrologer_dashboard/liveSession'))
const Routers: React.FC = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#0D0D0D;",
        light: "#F2C262",
      },
      secondary: {
        main: "#2C5282",
        light: "#4A5568",
      },
      text: {
        primary: "#616161",
        secondary: "#4A5568",
      },
      info: {
        main: "#ffffff",
      },
    },
    typography: {
      fontFamily: "Lato",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 600,
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          {/* LogIn And SignUp Routes*/}
          {/* ======================= AUTH ROUTES ============================= */}
              <Route
                path={Login}
                element={<PublicRoute component={<LoginScreen />} />}
              />
            
              <Route
                path={SignUp}
                element={<PublicRoute component={<SignUpScreen />} />}
              />

              <Route
                path={verifyOtp}
                element={<PublicRoute component={<VerifyOtpScreen />} />}
              />

              <Route
                path={AdminLogin}
                element={<PublicRoute component={<AdminLoginPage />} />}
              />
          {/* ================================================================= */}

          {/* ======================= USER ROUTES ============================= */}
          <Route
            path={Home}
            element={<PublicRoute component={<HomeScreen />} />}
          />

          <Route
            path={ContactUs}
            element={<PublicRoute component={<ContactUsPage />} />}
          />  
           <Route
            path={AboutUs}
            element={<PublicRoute component={<AboutUsPage />} />}
          />      
          <Route
            path={Dashboard}
            element={<PrivateRoute role={3} component={<DashboardScreen />} />}
          />
           <Route
            path={settings}
            element={<PrivateRoute role={3} component={<SettingsScreen />} />}
          />

          <Route
            path={"/"}
            element={<PublicRoute component={<LandingPage />} />}
          />
           
          
          <Route
            path={Astrologers}
            element={<PublicRoute component={<AstrologersPage />} />}
          />
           <Route
            path={AstrologerDetails}
            element={<PublicRoute component={<AstrologerDetailsPage />} />}
          />
           <Route
            path={Horoscope}
            element={<PublicRoute component={<HoroscopePage />} />}
          />
           <Route
            path={News}
            element={<PublicRoute component={<NewsPage />} />}
          />
           <Route
            path={News_detail}
            element={<PublicRoute component={<News_detail_page />} />}
          />
          <Route
            path={UserProfile}
            element={<PrivateRoute role={2} component={<UserProfileScreen />} />}
          />
           <Route
            path={EditProfile}
            element={<PrivateRoute role={2} component={<EditUserProfileScreen />} />}
          />

         

          <Route
            path={wallet}
            element={<PrivateRoute role={2} component={<Wallet_page />} />}
          />

          <Route
            path={transactions}
            element={<PrivateRoute role={2} component={<UserTransactiobsPage />} />}
          />
           <Route
            path={userCallHistory}
            element={<PrivateRoute role={2} component={<UserCallHistoryScreen />} />}
          />

          <Route
            path={live}
            element={<PublicRoute component={<LiveStreaming />} />}
          />    

          {/* <Route
            path={userCallFeedback}
            element={<PrivateRoute role={2} component={<UserFeedbackPage />} />}
          /> */}

          <Route
            path={UserTerms}
            element={<PublicRoute component={<UserTermsPage />} />}
          />
          <Route
            path={UserPrivacy}
            element={<PublicRoute component={<UserPrivacyPage />} />}
          />

          <Route
            path={Disclaimer}
            element={<PublicRoute component={<DisclaimerPage />} />}
          />

          <Route
            path={RefundPolicy}
            element={<PublicRoute component={<RefundPolicyPage />} />}
          />

               
          {/* ================================================================= */}


          {/* ======================= ASTROLOGER ROUTES ======================= */}
          <Route
            path={astro_dashboard}
            element={<PrivateRoute role={3} component={<Astro_Dashboard_Page />} />}
          />

          <Route
            path={astro_calls}
            element={<PrivateRoute role={3} component={<Astro_Calls_Page />} />}
          />
           <Route
            path={live_session}
            element={<PrivateRoute role={3} component={<LiveSession />} />}
          />

          <Route
            path={astro_payments}
            element={<PrivateRoute role={3} component={<Astro_Payments_Page />} />}
          />

          <Route
            path={astro_profile}
            element={<PrivateRoute role={3} component={<Astro_Profile_Page />} />}
          />

          <Route
            path={astro_edit_profile}
            element={<PrivateRoute role={3} component={<Astro_Profile_Edit_Page />} />}
          />
          {/* <Route
            path={astro_feedback}
            element={<PrivateRoute role={3} component={<Astro_Feedback_Page />} />}
          /> */}

          {/* ================================================================= */}



          {/* ======================= ADMIN ROUTES ============================ */}
          <Route
            path={Categories}
            element={<PrivateRoute role={1} component={<CategoriesPage />} />}
          />
           <Route
            path={InactiveAstrologer}
            element={<PrivateRoute role={1} component={<InactiveAstrologerPage />} />}
          />
           <Route
            path={BlockedAstrologer}
            element={<PrivateRoute role={1} component={<BlockedAstrologerPage />} />}
          />
           <Route
            path={AstrologersList}
            element={<PrivateRoute role={1} component={<AstrologersListPage />} />}
          />
           <Route
            path={admin_customers}
            element={<PrivateRoute role={1} component={<AdminCustomerPage />} />}
          />
           <Route
            path={ManageBlogs}
            element={<PrivateRoute role={1} component={<ManageArticlePage />} />}
          />
           <Route
            path={Testimonials}
            element={<PrivateRoute role={1} component={<ManageTestimonialsPage />} />}
          />
          <Route
            path={CallsList}
            element={<PrivateRoute role={1} component={<CallsListPage />} />}
          />
          <Route
            path={MissedCallsList}
            element={<PrivateRoute role={1} component={<MissedCallsPage />} />}
          />
          <Route
            path={AddHoroscope}
            element={<PrivateRoute role={1} component={<AddHoroscopePage />} />}
          />
           <Route
            path={Banner}
            element={<PrivateRoute role={1} component={<BannerListPage />} />}
          />
          <Route
            path={Packages}
            element={<PrivateRoute role={1} component={<PackagesPage />} />}
          />
          <Route
            path={Notification}
            element={<PrivateRoute role={1} component={<NotificationPage />} />}
          />
           <Route
            path={Privacy}
            element={<PrivateRoute role={1} component={<PrivacyPage />} />}
          />

          <Route
            path={Terms}
            element={<PrivateRoute role={1} component={<TermsPage />} />}
          />
            <Route
            path={'*'}
            element={<PrivateRoute role={1} component={<LandingPage />} />}
          />
          
        </Routes>
      </Router>
    </ThemeProvider>
  );
};
export default Routers;
