import { type } from 'os';
import { SendOtpModal, UserDataModal } from '../../modal'
import Utils from '../../Utils'
const { LOGIN } = Utils.ActionName
export const userDataReducer = (
    state: UserDataModal = new UserDataModal(),
    action: any
) => {
    switch (action.type) {
        // case USER_DATA:
        //     return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const SendOtpReducer = (
    state: SendOtpModal = new SendOtpModal(),
    action: any
) => {
    switch (action.type) {
         case LOGIN:
            return { ...state, ...action.payload };
        default: return state;
    }
}