import Utils from '../../../Utils';
import { liveAstrologersModel } from '../../../modal';

const { LIVEASTROLOGERSLIST } = Utils.ActionName;

export const liveAstrologersReducer = (
    state: liveAstrologersModel = new liveAstrologersModel(),
    action: any
) => {
    switch (action.type) {
        case LIVEASTROLOGERSLIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
