import { BlockedAstrologersModel } from "../../../modal";
import Utils from "../../../Utils";
const { ADMIN_NOTIFICATION_LIST } = Utils.ActionName



export const AdminNotificationListReducer = (
    state: BlockedAstrologersModel = new BlockedAstrologersModel(),
    action: any
) => {
    switch (action.type) {
        case ADMIN_NOTIFICATION_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};