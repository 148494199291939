import { AdspaceListModel, BannerListModel } from "../../../modal";
import Utils from "../../../Utils";
const { BANNER_LIST, ADSPACE_LIST } = Utils.ActionName

export const BannerListReducer = (
    state: BannerListModel = new BannerListModel(),
    action: any
) => {
    switch (action.type) {
        case BANNER_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const AdspaceListReducer = (
    state: AdspaceListModel = new AdspaceListModel(),
    action: any
) => {
    switch (action.type) {
        case ADSPACE_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};