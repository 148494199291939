import { CallEventModel } from '../../modal'
import Utils from '../../Utils'
const { CALL_EVENT } = Utils.ActionName


export const CallEventReucer = (
    state: CallEventModel = new CallEventModel(),
    action: any
) => {
    switch (action.type) {
        case CALL_EVENT:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

