import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import React, { Suspense } from "react";
import Header from "../../components/header";
import { useSelector } from "react-redux";
import { ReducersModal } from "../../modal";
import Utils from "../../Utils";
import Footer from "../../components/Footer";
import SideNavigation from "../../components/sideNavigation";
const styles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      paddingTop: "100px",
      paddingLeft: '20px',
      minHeight: "100vh",
      width: "100%",
      maxHeight: "100vh",
      // display: "flex",
      boxSizing: "border-box",
      [theme.breakpoints.between(0, 600)]: {
        paddingLeft: '0 !important',
        paddingTop: 0
      }
      // justifyContent: "space-between",
    },
    headerAndBodyContainer: {
      width: "100%",
      backgroundColor: "#F9FBFB",
      transition: "width 0.5s ease-in-out",
    },
    toggleOn: {
      transition: "width 0.5s ease-in-out",
      width: "98%",
    },
    bodyContainer: {
      width: "100%",
    },
    adminContainer: {
        paddingLeft: '280px'
    }
  })
);
interface Props {
  children?: any;
}
function AdminContainer({ children }: Props) {
  const classes = styles();
  const { toggle } = useSelector(
    (state: ReducersModal) => state.toggleSideNavReducer
  );
  return (
    <div className={classes.mainContainer}>
      <div
        className={
          toggle
            ? `${classes.headerAndBodyContainer} ${classes.toggleOn}`
            : classes.headerAndBodyContainer
        }
      >
      <SideNavigation/>
        <Suspense fallback={"Loading..."}>
          <div className={classes.adminContainer}>{children}</div>
        </Suspense>
        {/* <Footer/> */}
      </div>
    </div>
  );
}

export default AdminContainer;
