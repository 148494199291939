import { CustomerListModel } from "../../../modal";
import Utils from "../../../Utils";
const { CUSTOMER_LIST } = Utils.ActionName

export const CustomerListReducer = (
    state: CustomerListModel = new CustomerListModel(),
    action: any
) => {
    switch (action.type) {
        case CUSTOMER_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

